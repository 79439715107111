import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import React from 'react';

import { DealGPTQuestionStatus } from '../../Types/enums';

const ProgressRoot = styled('span')({
  backgroundColor: '#E8EEF8',
  position: 'relative',
  overflow: 'hidden',
  display: 'block',
  zIndex: '0',
  height: '100%',
  borderRadius: '10px',
  margin: '8px 0',
});

type ProgressBarProps = {
  answeredProgress: number;
  moreInfoProgress: number;
  notFoundProgress: number;
  skippedProgress: number;
};

const AnsweredProgressBar = styled('span')<ProgressBarProps>(
  ({ answeredProgress, moreInfoProgress, notFoundProgress, skippedProgress }) => ({
    borderRadiusLeft: '8px',
    // If there are no more info or not found questions
    borderRadiusRight: !(moreInfoProgress && notFoundProgress && skippedProgress) ? '8px' : 0,
    backgroundColor: '#CFEAD2', // green
    width: `${answeredProgress}%`,
    position: 'absolute',
    left: 0,
    bottom: 0,
    top: 0,
    transition: 'transform .4s linear',
    transformOrigin: 'left',
  })
);

const MoreInfoProgressBar = styled('span')<ProgressBarProps>(
  ({ answeredProgress, moreInfoProgress, notFoundProgress, skippedProgress }) => ({
    // If there are no answered questions
    borderRadiusLeft: !answeredProgress ? '8px' : 0,
    // If there are no not found questions
    borderRadiusRight: !(notFoundProgress && skippedProgress) ? '8px' : 0,
    backgroundColor: '#FFE9CA', // yellow
    width: `${moreInfoProgress}%`,
    position: 'absolute',
    left: `${answeredProgress}%`,
    bottom: 0,
    top: 0,
    transition: 'transform .4s linear',
    transformOrigin: 'left',
  })
);

const NotFoundProgressBar = styled('span')<ProgressBarProps>(
  ({ answeredProgress, moreInfoProgress, notFoundProgress, skippedProgress }) => ({
    // If there are no answered or more info questions
    borderRadiusLeft: !(answeredProgress && moreInfoProgress) ? '8px' : 0,
    borderRadiusRight: !skippedProgress ? '8px' : 0,
    backgroundColor: '#FFD4CA', // red
    width: `${notFoundProgress}%`,
    position: 'absolute',
    left: `${answeredProgress + moreInfoProgress}%`,
    bottom: 0,
    top: 0,
    transition: 'transform .4s linear',
    transformOrigin: 'left',
  })
);

const SkippedProgressBar = styled('span')<ProgressBarProps>(
  ({ answeredProgress, moreInfoProgress, notFoundProgress, skippedProgress }) => ({
    // If there are no answered or more info questions
    borderRadiusLeft: !(answeredProgress && moreInfoProgress && notFoundProgress) ? '8px' : 0,
    borderRadiusRight: '8px',
    backgroundColor: 'lightgrey', // red
    width: `${skippedProgress}%`,
    position: 'absolute',
    left: `${answeredProgress + moreInfoProgress + notFoundProgress}%`,
    bottom: 0,
    top: 0,
    transition: 'transform .4s linear',
    transformOrigin: 'left',
  })
);

type ProgressTooltipProps = {
  bgColor?: string;
  fontColor: string;
};

const ProgressTooltip = styled(({ className, ...props }: TooltipProps & ProgressTooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ bgColor, fontColor }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: bgColor ? bgColor : 'transparent',
    color: fontColor,
    padding: 0,
    fontSize: '14px',
  },
}));

const tooltipPosition = {
  popper: {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -8],
        },
      },
    ],
  },
};

const statusNameMap = {
  [DealGPTQuestionStatus.ANSWERED]: 'Answered',
  [DealGPTQuestionStatus.MORE_INFO_NEEDED]: 'More Info Needed',
  [DealGPTQuestionStatus.NOT_FOUND]: 'Not Found',
  [DealGPTQuestionStatus.SKIPPED]: 'Skipped',
};

type StatusProgressBarProps = {
  quantity: number;
  quantityAnswered?: number;
  quantityMoreInfoNeeded?: number;
  quantityNotFound?: number;
  quantitySkipped?: number;
};

const StatusProgressBar = ({
  quantity,
  quantityAnswered,
  quantityMoreInfoNeeded,
  quantityNotFound,
  quantitySkipped,
}: StatusProgressBarProps): JSX.Element => {
  let progress = {
    answered: 0,
    moreInfoNeeded: 0,
    notFound: 0,
    skipped: 0,
  };

  if (quantity !== 0) {
    const calculateProgress = (questionQuantity?: number) => {
      return ((questionQuantity || 0) / quantity) * 100;
    };

    progress = {
      answered: calculateProgress(quantityAnswered),
      moreInfoNeeded: calculateProgress(quantityMoreInfoNeeded),
      notFound: calculateProgress(quantityNotFound),
      skipped: calculateProgress(quantitySkipped),
    };
  }

  return (
    <ProgressRoot>
      <ProgressTooltip
        fontColor="#1F6D12"
        title={`${Math.round(progress.answered)}% ${statusNameMap[DealGPTQuestionStatus.ANSWERED]}`}
        placement="bottom-start"
        slotProps={tooltipPosition}
      >
        <AnsweredProgressBar
          answeredProgress={progress.answered}
          moreInfoProgress={progress.moreInfoNeeded}
          notFoundProgress={progress.notFound}
          skippedProgress={progress.skipped}
        />
      </ProgressTooltip>
      <ProgressTooltip
        fontColor="#B66D03"
        title={`${Math.round(progress.moreInfoNeeded)}% ${
          statusNameMap[DealGPTQuestionStatus.MORE_INFO_NEEDED]
        }`}
        placement="bottom-start"
        slotProps={tooltipPosition}
      >
        <MoreInfoProgressBar
          answeredProgress={progress.answered}
          moreInfoProgress={progress.moreInfoNeeded}
          notFoundProgress={progress.notFound}
          skippedProgress={progress.skipped}
        />
      </ProgressTooltip>
      <ProgressTooltip
        fontColor="#E02F10"
        title={`${Math.round(progress.notFound)}% ${
          statusNameMap[DealGPTQuestionStatus.NOT_FOUND]
        }`}
        placement="bottom-end"
        slotProps={tooltipPosition}
      >
        <NotFoundProgressBar
          answeredProgress={progress.answered}
          moreInfoProgress={progress.moreInfoNeeded}
          notFoundProgress={progress.notFound}
          skippedProgress={progress.skipped}
        />
      </ProgressTooltip>
      <ProgressTooltip
        fontColor="grey"
        title={`${Math.round(progress.skipped)}% ${statusNameMap[DealGPTQuestionStatus.SKIPPED]}`}
        placement="bottom-end"
        slotProps={tooltipPosition}
      >
        <SkippedProgressBar
          answeredProgress={progress.answered}
          moreInfoProgress={progress.moreInfoNeeded}
          notFoundProgress={progress.notFound}
          skippedProgress={progress.skipped}
        />
      </ProgressTooltip>
    </ProgressRoot>
  );
};

export default StatusProgressBar;
