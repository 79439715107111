import './init';

import type {} from '@mui/lab/themeAugmentation';
import { Grow, styled, ThemeProvider } from '@mui/material';
import { MaterialDesignContent, SnackbarKey, SnackbarProvider } from 'notistack';
import React, { useCallback } from 'react';

import Providers from './components/Providers/Providers';
import SnackbarCloseButton from './components/SnackbarCloseButton';
import ProvidedApp from './ProvidedApp';
import theme from './theme/theme';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent': {
    fontFamily: 'IBM Plex Sans',
  },
}));

function App() {
  // For adding a close button to snackbars
  const SnackbarCloseButtonAction = useCallback(
    (key: SnackbarKey) => <SnackbarCloseButton snackbarKey={key} />,
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={15000}
        preventDuplicate={true}
        TransitionComponent={Grow}
        action={(key) => SnackbarCloseButtonAction(key)}
        Components={{
          default: StyledMaterialDesignContent,
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
          warning: StyledMaterialDesignContent,
          info: StyledMaterialDesignContent,
        }}
      >
        <Providers>
          <ProvidedApp />
        </Providers>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
