import { Button, Container, List, styled, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import {
  getLatestRun,
  getRunStatus,
  getScopeQuestions,
  updateAnalysisQuestionFeedback,
} from '../../../services/dealGPT';
import { PageInfo, ProjectRunAnalysisQuestion, ProjectType } from '../../../Types/dealGPT';
import { AnalysisQuestionType, RunType } from '../../../Types/enums';
import { FeedbackInterface } from '../../../Types/feedback';
import LoadingMask from '../LoadingMask';
import AnalysisNavItem from '../PhaseTwo/AnalysisNavItem';
import Notes from '../PhaseTwo/Notes';
import StepPage from '../PhaseTwo/StepPage';

type AnalysisProps = {
  project: ProjectType;
};

const NavList = styled(List)({
  display: 'flex',
  gap: '2px',
  marginBottom: '24px',
});

const Analysis = ({ project }: AnalysisProps): JSX.Element => {
  const scope = useParams<{ scope?: string }>().scope || '';
  const [pages, setPages] = useState<PageInfo[]>([]);
  const [shouldFetchQuestions, setShouldFetchQuestions] = useState<boolean>(true);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [highestStepIndex, setHighestStepIndex] = useState<number>(0);
  const [latestRunId, setLatestRunId] = useState<string | null>();
  const [initialQuestions, setInitialQuestions] = useState<ProjectRunAnalysisQuestion[]>();
  const [questions, setQuestions] = useState<ProjectRunAnalysisQuestion[]>();
  const [isLoadingThis, setIsLoadingThis] = useState<boolean>(false);
  const [isLoadingApplyChanges, setIsLoadingApplyChanges] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleNextPage = () => {
    setStepIndex(stepIndex + 1);

    if (stepIndex + 1 > highestStepIndex) {
      setHighestStepIndex(stepIndex + 1);
    }

    if (stepIndex == pages.length - 1) {
      navigate(`/intellio-advantage/${project.id}/scope-areas`);
    }
  };

  const handlePreviousPage = () => {
    setStepIndex(stepIndex - 1);
  };

  const reprocessingTriggered = () => {
    console.log('reprocessing');
    setShouldFetchQuestions(true);
  };

  useEffect(() => {
    if (initialQuestions?.length && initialQuestions.length > 0) {
      // TODO add extra logic to this map to handle whether or not its loading or not
      // see if at least one question in questions is pending or something like that
      // console.log('initialQuestions', initialQuestions);

      // get distinct page names from initialQuestions
      const distinctPageNames = Array.from(
        new Set(initialQuestions?.map((question) => question.pageName))
      );

      setPages(
        distinctPageNames?.map((name, index) => ({
          name,
          sequence: index,
        })) || []
      );
    }
  }, [initialQuestions]);

  // check if run exists
  useEffect(() => {
    const checkLatestRun = async () => {
      const runResponse = await getLatestRun({
        projectId: project.id,
        scope,
        runType: RunType.ANALYSIS,
      });

      if (runResponse._data) {
        setLatestRunId(runResponse._data.id);
      } else {
        setLatestRunId(null);
      }
    };

    // Only attempt to get the latest run if we aren't trying to do a fresh run
    if (!searchParams.has('fresh-run')) {
      checkLatestRun();
    } else {
      setLatestRunId(null);
    }
  }, [project.id, scope, searchParams]);

  useEffect(() => {
    if (!latestRunId && latestRunId !== null) {
      return;
    }

    let interval: NodeJS.Timer;
    const intervalTime = 3000;

    // Get all scope questions, including all previous versions
    const fetchAllScopeQuestions = async () => {
      console.log('fetching questions');

      const response = await getScopeQuestions({
        projectId: project.id,
        scope,
        // If fresh run is true, don't pass runId so a new run is created
        // otherwise, use the latestRunId if it's defined
        runId: searchParams.get('fresh-run') === 'true' ? undefined : latestRunId || undefined,
      });

      if (response._data) {
        console.log('questions', response._data);

        setInitialQuestions(response._data.filter((question) => question.status === 'INITIAL'));
        setLatestRunId(response._data[0].runId);

        // Determine if the run is complete
        const status = await getRunStatus(project.id, scope, response._data[0].runId);

        if (!status.toLowerCase().startsWith('currently scanning')) {
          setQuestions(
            response._data.filter(
              (question) => question.status === 'ANSWERED' || question.status === 'INPUT_PENDING'
            )
          );
          setShouldFetchQuestions(false);
          setIsLoadingThis(false);
          setIsLoadingApplyChanges(false);
          clearInterval(interval);
        }

        // console.log('searchParams', searchParams.get('fresh-run') === 'true');
        if (searchParams.get('fresh-run') === 'true') {
          navigate(`/intellio-advantage/${project.id}/analysis/${scope}`);
        }
      }
    };
    if (shouldFetchQuestions) {
      setIsLoadingThis(true);
      setIsLoadingApplyChanges(true);
      fetchAllScopeQuestions();
      interval = setInterval(() => fetchAllScopeQuestions(), intervalTime);
    }

    return () => {
      clearInterval(interval);
    };
  }, [project.id, scope, latestRunId, shouldFetchQuestions, searchParams, navigate]);

  const initialPageQuestions = useMemo(() => {
    if (initialQuestions && pages.length > 0) {
      return initialQuestions.filter((question) => question.pageName === pages[stepIndex].name);
    }
  }, [initialQuestions, pages, stepIndex]);

  const pageQuestions = useMemo(() => {
    if (questions && pages.length > 0) {
      return questions.filter((question) => question.pageName === pages[stepIndex].name);
    }
  }, [questions, pages, stepIndex]);

  const handleFeedbackSubmit = async (
    feedback: FeedbackInterface,
    projectAnalysisQuestionId: string
  ) => {
    await updateAnalysisQuestionFeedback(projectAnalysisQuestionId, feedback);

    setQuestions(
      (prevQuestions) =>
        prevQuestions?.map((question) =>
          question.id === projectAnalysisQuestionId
            ? {
                ...question,
                analysisQuestionFeedback: feedback,
              }
            : question
        ) || []
    );
  };

  return (
    <div>
      <div style={{ marginBottom: '20px', display: 'flex' }}>
        <Typography variant="h2" sx={{ color: 'grey', paddingRight: '10px', fontSize: '24px' }}>
          Scope:
        </Typography>
        <Typography variant="h2" sx={{ fontSize: '24px' }}>
          {scope}
        </Typography>
      </div>
      <NavList>
        {pages.map((page, index) => (
          <AnalysisNavItem
            index={index}
            stepIndex={stepIndex}
            highestStepIndex={highestStepIndex}
            pageName={page.name}
            setStepIndex={setStepIndex}
            setHighestStepIndex={setHighestStepIndex}
            key={page.name}
            isLoadingThis={isLoadingThis}
            isLoadingApplyChanges={isLoadingApplyChanges}
            pageQuestions={questions?.filter((question) => question.pageName === page.name) ?? []}
          />
        ))}
      </NavList>
      <Container
        sx={{
          backgroundColor: '#ffffff',
          padding: '32px',
        }}
      >
        {stepIndex > 0 && (
          <Button
            variant="outlined"
            onClick={handlePreviousPage}
            disabled={questions?.length === 0 || isLoadingThis}
            style={{ position: 'absolute', right: '150px', zIndex: 10 }}
          >
            Previous Page
          </Button>
        )}
        {stepIndex !== pages.length - 1 && (
          <Button
            variant="contained"
            onClick={handleNextPage}
            disabled={questions?.length === 0 || isLoadingThis}
            style={{ position: 'absolute', right: '32px', zIndex: 10 }}
          >
            Next Page
          </Button>
        )}
        {stepIndex == pages.length - 1 && (
          <Button
            variant="contained"
            onClick={handleNextPage}
            disabled={questions?.length === 0 || isLoadingThis}
            style={{ minWidth: '104px', position: 'absolute', right: '32px', zIndex: 10 }}
          >
            Finish
          </Button>
        )}
        <LoadingMask isLoading={isLoadingThis} />
        {/* ASSUMPTION - notes will always be the first step */}
        {stepIndex === 0 && (
          <Notes
            project={project}
            scope={scope ?? ''}
            question={initialQuestions?.find(
              (question) => question.type === AnalysisQuestionType.NOTES
            )}
            reprocessingTriggered={reprocessingTriggered}
            latestRunId={latestRunId || undefined}
            firstRun={Number(questions?.length) === 0}
          />
        )}
        {stepIndex > 0 && (
          <StepPage
            page={pages[stepIndex]}
            initialPageQuestions={initialPageQuestions}
            pageQuestions={pageQuestions}
            latestRunId={latestRunId}
            setIsLoadingThis={setIsLoadingThis}
            setIsLoadingApplyChanges={setIsLoadingApplyChanges}
            reprocessingTriggered={reprocessingTriggered}
            handleFeedbackSubmit={handleFeedbackSubmit}
          />
        )}
      </Container>
    </div>
  );
};

export default Analysis;
