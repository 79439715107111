import { Person } from '@mui/icons-material';
import { Box, Button, Grid, Slider, styled, TextField, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UserMetadata } from 'Types/user';

import NavDrawer from '../components/Drawer/NavDrawer';
import LlmSelect from '../components/LlmSelect';
import NavBar, { NavBarMode } from '../components/NavBar';
import { useAuth } from '../components/Providers/AuthProvider';
import { setLLM } from '../redux/reducers/appStatusReducer';
import { AppDispatch } from '../redux/store';
import { updateUserMetadata, updateUserPreferredName } from '../services/users';
import {
  drawerWidth,
  MainContainer,
  PageContainer,
  ProfileAndNotesDiv,
  StyledGridContainer,
} from '../theme/CustomComponents';
import { Llm } from '../Types/enums';

const Content = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  overflowY: 'auto',
  height: '100%',
  width: '100%',
});

const PersonalInformation = styled('div')({
  display: 'flex',
  marginBottom: '20px',
});

const ProfilePhoto = styled('img')({
  width: '80px',
  height: '80px',
  borderRadius: '50%',
});

const GeneralSettings = styled('div')({
  background: 'white',
  padding: '15px',
  marginBottom: '40px',
});

const AdvancedSettings = styled('div')({
  background: 'white',
  padding: '15px',
});

const h1 = { fontSize: '32px', color: '#F900D3', fontWeight: 'bold' };
const h2 = { marginTop: '5px', color: '#070154' };
const h4 = {
  color: '#070154',
  fontWeight: 'bold',
  fontSize: '20px',
  display: 'flex',
};

const StyledSubmitButton = styled(Button)(() => {
  return {
    height: '40px',
    width: '80px',
    marginLeft: '10px',
  };
});

const ProfileSettingsPage = (): JSX.Element => {
  const profilePhoto: string | null = localStorage.getItem('profilePhoto')
    ? localStorage.getItem('profilePhoto')
    : null;
  const { currentUser, setCurrentUser } = useAuth();
  const [tempPogress, setTempPogress] = useState(currentUser?.metadata?.defaultTemperature || 0.5);
  const [historyDrawerOpen, setHistoryDrawerOpen] = useState<boolean>(
    window.matchMedia('(min-width: 960px)').matches
  );
  const [isEditing, setisEditing] = useState(false);
  const nameRef = useRef<HTMLInputElement>();
  const dispatch = useDispatch<AppDispatch>();

  const updateLlmUserPreference = async (newLlm: Llm) => {
    if (currentUser) {
      const userMetadata: UserMetadata = {
        ...currentUser.metadata,
        defaultLlm: newLlm,
      };
      dispatch(setLLM(newLlm));
      await updateUserMetadata(userMetadata).then((response) => setCurrentUser(response));
    }
  };

  const updateTemperatureUserPreference = async (newTemperature: number) => {
    if (currentUser) {
      const userMetadata: UserMetadata = {
        ...currentUser.metadata,
        defaultTemperature: newTemperature,
      };
      await updateUserMetadata(userMetadata).then((response) => setCurrentUser(response));
    }
  };

  return (
    <PageContainer>
      <NavDrawer
        drawerWidth={drawerWidth}
        drawerOpen={historyDrawerOpen}
        setDrawerOpen={setHistoryDrawerOpen}
      />
      <MainContainer open={historyDrawerOpen}>
        <NavBar mode={NavBarMode.NIGEL} />
        <Content>
          <ProfileAndNotesDiv>
            <PersonalInformation>
              {profilePhoto ? (
                <ProfilePhoto src={profilePhoto} alt="Profile"></ProfilePhoto>
              ) : (
                <Person
                  style={{
                    width: '80px',
                    height: '80px',
                    borderRadius: '50%',
                  }}
                />
              )}

              <Box style={{ marginLeft: '10px' }}>
                <Typography variant="h4" sx={{ mt: '12px', mb: '5px' }} style={h4}>
                  {currentUser?.preferredName}
                </Typography>
                <Typography style={h2}>{currentUser?.email}</Typography>
              </Box>
            </PersonalInformation>

            <GeneralSettings>
              <Typography variant="h1" style={h1}>
                General settings
              </Typography>

              <Typography variant="h4" sx={{ mt: '30px', mb: '10px' }} style={h4}>
                Default LLM
              </Typography>

              <Typography variant="body2" sx={{ mb: '10px', color: '#070154' }}>
                The LLM selected will be used for all prompts and conversations
              </Typography>

              <LlmSelect
                value={currentUser?.metadata?.defaultLlm || ''}
                onLlmChange={updateLlmUserPreference}
              ></LlmSelect>
              <br />
              <div style={{ display: 'flex' }}>
                <Typography variant="h4" sx={{ mt: '30px', mb: '10px' }} style={h4}>
                  Preferred Name
                </Typography>
                {!isEditing && (
                  <StyledSubmitButton
                    type="submit"
                    onClick={() => setisEditing(true)}
                    variant="outlined"
                    color="success"
                    style={{ alignSelf: 'center' }}
                  >
                    Edit
                  </StyledSubmitButton>
                )}
              </div>

              {isEditing ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField defaultValue={currentUser?.preferredName} inputRef={nameRef} />
                  <StyledSubmitButton
                    type="submit"
                    onClick={() => {
                      updateUserPreferredName({
                        preferredName: nameRef.current?.value ?? '',
                      }).then((user) => {
                        setCurrentUser(user);
                      });
                      setisEditing(false);
                    }}
                    variant="contained"
                    color="success"
                  >
                    Save
                  </StyledSubmitButton>
                </div>
              ) : (
                <Typography variant="body2">{currentUser?.preferredName}</Typography>
              )}
              <br />
            </GeneralSettings>

            <AdvancedSettings>
              <Typography variant="h1" style={h1}>
                Advanced settings
              </Typography>
              <Typography
                variant="h4"
                sx={{ mt: '30px', mb: '10px' }}
                style={h4}
                id={'profile-settings-temp-label'}
              >
                Temperature
              </Typography>
              <Typography variant="body2" sx={{ color: '#070154' }}>
                In the context of AI, the term &quot;temperature&quot; is used to describe the
                randomness of the AI&apos;s responses. A higher temperature means the AI&apos;s
                responses will be more diverse and creative, but might also be more unpredictable or
                nonsensical. On the other hand, a lower temperature means the AI&apos;s responses
                will be consistent, and deterministic, but might also be less creative. In other
                words, the setting can be used to control the trade-off between randomness and
                consistency in responses.
              </Typography>

              <StyledGridContainer sx={{ mt: '20px' }} container={true}>
                <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                  🤖
                </Grid>
                <Grid item xs={10}>
                  <Slider
                    aria-labelledby={'profile-settings-temp-label'}
                    min={0}
                    max={1}
                    step={0.1}
                    marks
                    valueLabelDisplay="auto"
                    value={tempPogress}
                    onChange={(_, newValue) => {
                      setTempPogress(+newValue);
                      updateTemperatureUserPreference(+newValue);
                    }}
                    sx={{ color: '#0047FF' }}
                  />
                </Grid>
                <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                  🍄
                </Grid>
              </StyledGridContainer>
            </AdvancedSettings>
          </ProfileAndNotesDiv>
        </Content>
      </MainContainer>
    </PageContainer>
  );
};

export default ProfileSettingsPage;
