import { Button, Container, MenuItem, styled, Typography } from '@mui/material';
import { StatusCodes } from 'http-status-codes';
import { makeValidate, TextField } from 'mui-rff';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { editProject, getProject, getProjectTypes, saveProject } from '../../../services/dealGPT';
import type { CreateProjectOptions, Project, UpdateProjectOptions } from '../../../Types/dealGPT';
import { ProjectStatus, UserRole } from '../../../Types/enums';
import type { Result } from '../../../Types/result';
import DealGPTContainer from '../../DealGPT/ViewModels/DealGPTContainer';
import { useAuth } from '../../Providers/AuthProvider';
import LoadingMask from '../LoadingMask';

const ProjectContainer = styled('div')(() => ({
  width: '100%',
  position: 'relative',
  zIndex: 1,
  background: 'white',
  height: '100%',
  overflowY: 'auto',
}));

const StyledForm = styled('form')({
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  margin: '0 20px 20px 20px',
});

const StyledButton = styled(Button)({
  width: '120px',
  height: '35px',
  marginLeft: '20px',
});

const regexNoStartEndWhiteSpace = /^\S*(\s+[^\s]+)*$/;
const errorMessageNoWhiteSpace = 'Cannot start or end with white space.';
const errorMessageRequired = 'This field is required.';

const schema = Yup.object({
  clientName: Yup.string()
    .required(errorMessageRequired)
    .matches(regexNoStartEndWhiteSpace, errorMessageNoWhiteSpace),
  name: Yup.string()
    .required(errorMessageRequired)
    .matches(regexNoStartEndWhiteSpace, errorMessageNoWhiteSpace),
  description: Yup.string().optional().matches(regexNoStartEndWhiteSpace, errorMessageNoWhiteSpace),
  gleanAppId: Yup.string()
    .required(errorMessageRequired)
    .matches(regexNoStartEndWhiteSpace, errorMessageNoWhiteSpace),
  teamsId: Yup.string().uuid('Must be a valid UUID.').required(errorMessageRequired),
  opportunityId: Yup.string()
    .required(errorMessageRequired)
    .matches(regexNoStartEndWhiteSpace, errorMessageNoWhiteSpace),
  channelId: Yup.string().optional().matches(regexNoStartEndWhiteSpace, errorMessageNoWhiteSpace),
});

const initialValues = {
  id: '',
  clientName: '',
  projectType: '',
  name: '',
  description: '',
  status: ProjectStatus.OPEN,
  gleanAppId: '',
  teamsId: '',
  opportunityId: '',
  channelId: '',
};

const validate = makeValidate(schema);

const DealGPTCreateProjectContainer = (): JSX.Element => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { projectId } = useParams();
  const [currentProject, setCurrentProject] = useState<UpdateProjectOptions>(initialValues);
  const [projectTypes, setProjectTypes] = useState<string[]>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProject = async () => {
      setIsLoading(true);
      if (projectId) {
        const projectResponse = await getProject(
          projectId,
          currentUser?.roles?.includes(UserRole.DEALGPT_ADMIN)
        );

        if (projectResponse._isSuccess) {
          if (projectResponse._data) {
            const project = {
              clientName: projectResponse._data.clientName,
              name: projectResponse._data.name,
              projectType: projectResponse._data.projectType,
              description: projectResponse._data.description,
              status: projectResponse._data.status,
              gleanAppId: projectResponse._data.gleanAppId,
              teamsId: projectResponse._data.teamsId,
              opportunityId: projectResponse._data.opportunityId,
              channelId: projectResponse._data.channelId ?? undefined,
            };
            setCurrentProject(project as UpdateProjectOptions);
          }
        } else {
          enqueueSnackbar(`Failed to fetch project: ${projectResponse._error}`, {
            variant: 'error',
          });
          if (projectResponse._statusCode === StatusCodes.UNAUTHORIZED) {
            navigate('/intellio-advantage', { replace: true });
          }
        }
      }
      setProjectTypes(await getProjectTypes());
      setIsLoading(false);
    };

    // Fetch project initially
    fetchProject();
  }, [projectId, currentUser?.roles, navigate]);

  const handleResponse = (projectResponse: Result<Project>) => {
    if (projectResponse._isSuccess) {
      enqueueSnackbar('Project saved!', { variant: 'success', autoHideDuration: 5000 });
    } else {
      enqueueSnackbar('Failed to save project!', { variant: 'error', autoHideDuration: 5000 });
    }
  };

  const onSubmit = async (values: UpdateProjectOptions) => {
    const project = {
      name: values['name'],
      clientName: values['clientName'],
      projectType: values['projectType'],
      description: values['description'],
      gleanAppId: values['gleanAppId'],
      opportunityId: values['opportunityId'],
      teamsId: values['teamsId'],
      channelId: values['channelId'],
    } as UpdateProjectOptions;

    let response: Result<Project> | undefined = undefined;
    let projectToRedirect = projectId;
    if (!projectId) {
      response = await saveProject(project as CreateProjectOptions);
      projectToRedirect = response._data?.id;
    } else {
      project.id = projectId;
      project.status = values['status'];
      response = await editProject(project);
    }

    handleResponse(response);
    if (projectToRedirect) {
      navigate(`/intellio-advantage/${projectToRedirect}`);
    } else {
      navigate('/intellio-advantage');
    }
  };

  const handleCancel = () => {
    if (projectId) {
      navigate(`/intellio-advantage/${projectId}`);
    } else {
      navigate('/intellio-advantage');
    }
  };

  return (
    <DealGPTContainer>
      <ProjectContainer>
        <LoadingMask isLoading={isLoading} />
        <Container maxWidth="md">
          <Form
            validate={validate}
            onSubmit={onSubmit}
            initialValues={currentProject}
            render={({ handleSubmit }) => (
              <StyledForm onSubmit={handleSubmit}>
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: '700',
                    fontSize: '20px',
                    marginTop: '20px',
                    marginBottom: '5px',
                  }}
                >
                  {projectId ? 'Edit Project' : 'Create Project'}
                </Typography>
                <TextField
                  label="Client Name"
                  name={'clientName'}
                  helperText="ex: ABC"
                  required
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                />

                <TextField label="Project Type" name={'projectType'} select>
                  {projectTypes ? (
                    projectTypes.map((option) => (
                      <MenuItem value={option} key={option}>
                        {option}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem></MenuItem>
                  )}
                </TextField>

                <TextField
                  label="Project Name"
                  name={'name'}
                  helperText="ex: Project New"
                  required
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                />
                <TextField
                  label="Description"
                  name={'description'}
                  multiline
                  required
                  minRows={2}
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                />
                {projectId && (
                  <TextField label="Status" name={'status'} select>
                    {Object.values(ProjectStatus).map((option) => (
                      <MenuItem value={option} key={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                <TextField
                  label="Glean App Id"
                  name={'gleanAppId'}
                  helperText="ex: 8vy70lqan56g6XXX"
                  required
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                />
                <TextField
                  label="Teams Id"
                  name={'teamsId'}
                  required
                  helperText="ex: 9ff7c6d1-1606-4a26-XXbc-7bd9b48fXXXX"
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                />
                <TextField
                  label="Opportunity Id"
                  name={'opportunityId'}
                  helperText="ex: 006Nu000002YnAgXXX"
                  required
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                />
                <TextField
                  label="Channel Id"
                  name={'channelId'}
                  helperText="ex: 19%3Acf9f54bapr2855771a147a1ae884XXXXf%40thread.tacv2"
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <StyledButton id="cancel-button" variant="outlined" onClick={handleCancel}>
                    Cancel
                  </StyledButton>
                  <StyledButton id="save-button" type="submit" variant="contained">
                    Save
                  </StyledButton>
                </div>
              </StyledForm>
            )}
          />
        </Container>
      </ProjectContainer>
    </DealGPTContainer>
  );
};

export default DealGPTCreateProjectContainer;
