import { Button, Container, Drawer as MuiDrawer, styled } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getConversations } from '../../redux/actions/conversationActions';
import { selectAppStatus } from '../../redux/reducers/appStatusReducer';
import { selectConversations, setIsLoading } from '../../redux/reducers/conversationReducer';
import {
  selectDrawerOpen,
  setDrawerOpen as setDrawerOpenReducer,
} from '../../redux/reducers/drawerOpenReducer';
import { AppDispatch } from '../../redux/store';
import { setFocusById } from '../../services/util';
import { AppEnum } from '../../Types/enums';
import { useFeatures } from '../Providers/FeatureProvider';
import ConversationList from './ConversationList';
import DrawerApps from './DrawerApps';
import DrawerToggleButton from './DrawerToggleButton';

type NavDrawerContainerProps = {
  loading: boolean;
};

// We need this because of the children that this container wraps
const NavDrawerContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'loading',
})<NavDrawerContainerProps>(({ loading }) => ({
  overflowY: 'auto',
  padding: '24px',
  backgroundColor: 'white',
  height: '100vh',
  pointerEvents: loading ? 'none' : 'auto',
  opacity: loading ? '0.5' : 1,
}));

type ContainerProps = {
  open: boolean;
};

interface Props {
  children?: React.ReactNode;
  drawerWidth: string;
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
  showNewChatButton?: boolean;
  showConversationList?: boolean;
  handleConversationUpdate?: (
    conversationId?: string,
    projectId?: string,
    shouldFetchConversations?: boolean
  ) => void;
  setShowNormalTextField?: (show: boolean) => void;
}

const NavDrawer: React.FC<Props> = ({
  children,
  drawerWidth,
  drawerOpen,
  setDrawerOpen,
  showNewChatButton = true,
  showConversationList = true,
  handleConversationUpdate,
  setShowNormalTextField,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const features = useFeatures();
  const { conversations, isLoading } = useSelector(selectConversations);
  const { selectedApp } = useSelector(selectAppStatus);
  const isDrawerOpen = useSelector(selectDrawerOpen);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const shouldScrollRef = useRef<boolean>(false);

  const DrawerContainer = styled('div')<ContainerProps>(({ open }) => ({
    width: open ? drawerWidth : 'auto',
    zIndex: '1',
  }));

  const defaultHandleConversationUpdate = (
    conversationId?: string,
    projectId?: string,
    shouldFetchConversations = true
  ) => {
    if (shouldFetchConversations) {
      dispatch(getConversations());
    }

    if (setShowNormalTextField) {
      setShowNormalTextField(true);
    }

    let route = '/chat';

    if (projectId) {
      route = `/intellio-advantage/${projectId}/chat`;
    }

    if (conversationId) {
      navigate(`${route}/${conversationId}`);
    } else {
      navigate(route);
    }

    setFocusById('chat-box');
  };

  const loadMoreConversations = () => {
    dispatch(setIsLoading(true));
    shouldScrollRef.current = true;
    dispatch(getConversations({ pageAction: 'increment' }));
  };

  const handleDrawerToggle = () => {
    dispatch(setDrawerOpenReducer(!isDrawerOpen));
    setDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    const adjustScroll = () => {
      if (shouldScrollRef.current && containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
        shouldScrollRef.current = false;
        dispatch(setIsLoading(false));
      }
    };

    adjustScroll();
  }, [conversations, dispatch]);

  return (
    <>
      <DrawerContainer open={drawerOpen}>
        <MuiDrawer
          variant="persistent"
          anchor="left"
          open={drawerOpen}
          sx={{ height: '100%', width: drawerWidth }}
        >
          <NavDrawerContainer loading={isLoading} ref={containerRef}>
            <DrawerApps />
            {showNewChatButton && (
              <>
                <Button
                  variant="contained"
                  sx={{ width: '100%', marginTop: '16px' }}
                  onClick={() => {
                    navigate('/chat');
                    setFocusById('chat-box');
                  }}
                >
                  New Chat
                </Button>
                {features.private_chat && selectedApp === AppEnum.NIGEL && (
                  <Button
                    variant="contained"
                    sx={{ width: '100%', marginTop: '16px' }}
                    onClick={() => {
                      navigate('/chat-private');
                    }}
                  >
                    New Private Chat
                  </Button>
                )}
              </>
            )}
            {showConversationList && (
              <ConversationList
                onConversationUpdated={
                  handleConversationUpdate
                    ? handleConversationUpdate
                    : defaultHandleConversationUpdate
                }
                loadMoreConversations={loadMoreConversations}
              />
            )}
            {children && children}
          </NavDrawerContainer>
        </MuiDrawer>
      </DrawerContainer>
      <DrawerToggleButton
        isDrawerOpen={drawerOpen}
        setIsDrawerOpen={handleDrawerToggle}
        tooltipCaption={drawerOpen ? 'Close Panel' : 'Open Panel'}
      />
    </>
  );
};

export default NavDrawer;
