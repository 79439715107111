import { OpenInNew } from '@mui/icons-material';
import { List, ListItem, styled, Typography } from '@mui/material';
import React from 'react';

const StyledLink = styled('a')({
  color: 'inherit',
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'underline',
  },
});

const StyledListItem = styled(ListItem)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  paddingTop: '0',
  paddingBottom: '10px',
});

const OpenInNewIcon = (
  <OpenInNew sx={{ fontSize: '20px', marginRight: '2px', verticalAlign: 'text-top' }} />
);

const Trainings: React.FC = () => {
  return (
    <>
      <Typography
        variant="subtitle2"
        sx={{
          height: '20px',
          color: '#0047FF',
          margin: 0,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        TRAINING
      </Typography>
      <div style={{ height: 'inherit' }}>
        <List sx={{ height: 'inherit', marginTop: '10px', paddingTop: '0', paddingBottom: '0' }}>
          <StyledListItem>
            <Typography variant="body2">
              <StyledLink
                href="https://westmonroe.degreed.app/plan/3391588?newWindow=true"
                target="_blank"
                style={{
                  lineHeight: '20px',
                  fontSize: '14px',
                }}
              >
                {OpenInNewIcon}
                <b>Prompt Engineering Fundamentals</b>
              </StyledLink>
            </Typography>

            <Typography variant="body2">
              Gain knowledge and skills to leverage Generative AI in your work.
            </Typography>
          </StyledListItem>
          <StyledListItem>
            <Typography variant="body2">
              <StyledLink
                href="https://westmonroe.degreed.app/pathway/e9jg4zwd9w/pathway?newWindow=true"
                target="_blank"
                style={{
                  lineHeight: '20px',
                  fontSize: '14px',
                }}
              >
                {OpenInNewIcon}
                <b>Work Smarter, Not Harder Pathway</b>
              </StyledLink>
            </Typography>
            <Typography variant="body2">
              See tips & tricks to work efficiently and effectively using Nigel.
            </Typography>
          </StyledListItem>
          <StyledListItem>
            <Typography variant="body2">
              <StyledLink
                href="https://westmonroe.degreed.app/pathway/1pnydyow9n/pathway?newWindow=true"
                target="_blank"
                style={{
                  lineHeight: '20px',
                  fontSize: '14px',
                }}
              >
                {OpenInNewIcon}
                <b>Work Smarter, Not Harder Pathway</b>
              </StyledLink>
            </Typography>
            <Typography variant="body2">
              See how colleagues are using AI to augment their day to day.
            </Typography>
          </StyledListItem>
        </List>
      </div>
    </>
  );
};

export default Trainings;
