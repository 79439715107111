import { ChevronRightSharp, ExpandMoreSharp } from '@mui/icons-material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import {
  Button,
  CircularProgress,
  Container,
  Drawer,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import { usePrevious } from '../../hooks/usePrevious';
import { rescanQuestions } from '../../services/dealGPT';
import type { QuestionTypeTreeNode } from '../../Types/dealGPT';
import { DealGPTQuestionStatus } from '../../Types/enums';
import { AnalyticsEvent, useAnalytics } from '../Providers/AnalyticsProvider';
import QuestionStatus from './QuestionStatus';
import QuestionView from './QuestionView';

const StyledButton = styled(Button)({
  width: '200px',
  height: '35px',
});

const CategoryViewContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  margin: '40px auto',
});

const StyledTableRow = styled(TableRow)({
  '&:nth-of-type(even)': {
    backgroundColor: '#E8EEF8',
  },
});

const StyledTableCell = styled(TableCell)({
  padding: '12px 16px',
});

const StyledTableHeaderCell = styled(StyledTableCell)({
  fontWeight: '700',
  borderBottom: '2px solid #C4C4C4',
});

const StyledTypography = styled(Typography)({
  display: 'block',
  fontSize: '0.875rem',
});

const SourceDocumentsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  gap: '6px',
  color: '#1979CD',
});

const SourceDocumentsTypography = styled(StyledTypography)({
  width: '130px',
});

export type CategoryViewProps = {
  questions: QuestionTypeTreeNode[];
  expandAll: boolean;
  gleanAppId: string;
  setQuestions: (nodes: QuestionTypeTreeNode[]) => void;
  setQuestionsLoaded: (state: boolean) => void;
  setShouldFetchRunProgress: (isRescan: boolean) => void;
  isLoadingProjectRescan: boolean;
  setIsLoadingProjectRescan: (isLoading: boolean) => void;
};

const CategoryView = ({
  questions,
  expandAll,
  gleanAppId,
  setQuestions,
  setQuestionsLoaded,
  setShouldFetchRunProgress,
  isLoadingProjectRescan,
  setIsLoadingProjectRescan,
}: CategoryViewProps): JSX.Element => {
  const prevExpandAllRef = usePrevious(expandAll);

  const [openedQuestionId, setOpenedQuestionId] = useState<string | null>(null);
  const [expandedQuestions, setExpandedQuestions] = useState<Set<string>>(new Set());
  const [isLoadingRescan, setIsLoadingRescan] = useState<boolean>(false);
  const [currentRescanQuestions, setCurrentRescanQuestions] = useState<QuestionTypeTreeNode[]>([]);
  const analytics = useAnalytics();

  const flattenQuestionTreeNodes = useCallback(
    (arr: QuestionTypeTreeNode[]): QuestionTypeTreeNode[] => {
      return arr.reduce((acc: QuestionTypeTreeNode[], item: QuestionTypeTreeNode) => {
        acc.push(item);
        if (Array.isArray(item['children'])) {
          return acc.concat(flattenQuestionTreeNodes(item['children']));
        }
        return acc;
      }, []);
    },
    []
  );

  const flattendQuestions = useMemo(() => {
    return flattenQuestionTreeNodes(questions);
  }, [questions, flattenQuestionTreeNodes]);

  const allQuestionIds = useMemo(() => {
    const ids = new Set<string>();
    const collectAllQuestionIds = (question: QuestionTypeTreeNode) => {
      ids.add(question.id);
      question.children?.forEach(collectAllQuestionIds);
    };
    questions.forEach(collectAllQuestionIds);
    return ids;
  }, [questions]);

  useEffect(() => {
    if (prevExpandAllRef !== expandAll || expandAll) {
      expandAll ? setExpandedQuestions(new Set(allQuestionIds)) : setExpandedQuestions(new Set());
    }
  }, [expandAll, prevExpandAllRef, allQuestionIds]);

  const handleOpenQuestion = (questionId: string) => {
    analytics.event(AnalyticsEvent.WM_IA_D_QUESTION_CLICK, {
      id: questionId,
    });
    setOpenedQuestionId(questionId);
  };

  const handleCloseQuestion = () => {
    setOpenedQuestionId(null);
  };

  const toggleExpandQuestion = (questionId: string) => {
    setExpandedQuestions((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(questionId)) {
        newSet.delete(questionId);
      } else {
        newSet.add(questionId);
      }
      return newSet;
    });
  };

  const handleRescanQuestion = async (questionId?: string) => {
    try {
      let analyticsRescanValue = `Category: ${questions[0].category}, Questions:`;
      let flattendCategoryQuestions = flattendQuestions;

      // if questionId is present rescan only that question and its children, if not, then rescan all category questions.
      if (questionId) {
        analyticsRescanValue = '';
        const questionObject = flattendCategoryQuestions.find((q) => q.id === questionId);
        if (questionObject) {
          flattendCategoryQuestions = flattenQuestionTreeNodes([questionObject]);
        } else {
          throw new Error('Question not found.');
        }
      }
      setCurrentRescanQuestions(flattendCategoryQuestions);

      flattendCategoryQuestions = flattendCategoryQuestions.map((question) => ({
        ...question,
        children: [],
        response: undefined,
        explanation: undefined,
        gleanApiResponse: undefined,
        documents: [],
        validationResponse: null,
        validationGleanApiResponse: null,
        validationSources: [],
        projectQuestionFeedback: null,
      }));

      setIsLoadingRescan(true);
      setIsLoadingProjectRescan(true);
      analytics.event(AnalyticsEvent.WM_IA_D_RESCAN_QUESTIONS, {
        value:
          analyticsRescanValue +
          flattendCategoryQuestions.map((question) => question.id).toString(),
      });

      const response = await rescanQuestions({ questions: flattendCategoryQuestions, gleanAppId });

      if (response?._isSuccess) {
        //setting questionsloaded as false to refresh and fetch category question
        setQuestionsLoaded(false);
        setShouldFetchRunProgress(true);
        enqueueSnackbar(`Rescan completed successfully`, { variant: 'success' });
      } else {
        enqueueSnackbar(`Failed to rescan question: ${response?._error}`, {
          variant: 'error',
        });
      }
    } catch (error) {
      enqueueSnackbar(`Failed to rescan question: ${error}`, { variant: 'error' });
    }
    setIsLoadingRescan(false);
    setIsLoadingProjectRescan(false);
  };

  const generateHierarchicalQuestions = (
    question: QuestionTypeTreeNode,
    level = 0
  ): ReactElement[] => {
    const isExpanded = expandedQuestions.has(question.id);
    const questionStatuses: string[] = [];
    if (question.validationResponse) {
      questionStatuses.push('VALIDATED');
    }

    questionStatuses.push(question.status);

    const questionElement = (
      <StyledTableRow
        key={question.id}
        onClick={() => handleOpenQuestion(question.id)}
        sx={{ cursor: 'pointer' }}
      >
        <StyledTableCell
          sx={{ minWidth: '250px', alignItems: 'center', pl: `${level * 40 + 12}px` }}
          scope="row"
        >
          <div style={{ display: 'flex' }}>
            {question.children && question.children.length > 0 && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  toggleExpandQuestion(question.id);
                }}
              >
                {isExpanded ? <ExpandMoreSharp /> : <ChevronRightSharp />}
              </div>
            )}

            {question.question}
          </div>
        </StyledTableCell>
        <StyledTableCell sx={{ width: '100px' }}>
          {question.documents && question.documents.length === 1 && (
            <a
              href={question.documents[0].link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <SourceDocumentsContainer>
                <DescriptionOutlinedIcon sx={{ fontSize: '16px' }} />
                <SourceDocumentsTypography noWrap>
                  {question.documents[0].fileName}
                </SourceDocumentsTypography>
              </SourceDocumentsContainer>
            </a>
          )}
          {question.documents && question.documents.length > 1 && (
            <SourceDocumentsContainer>
              <DescriptionOutlinedIcon sx={{ fontSize: '16px' }} />
              <SourceDocumentsTypography noWrap>
                {question.documents.length} documents
              </SourceDocumentsTypography>
            </SourceDocumentsContainer>
          )}
        </StyledTableCell>
        <StyledTableCell align="right" sx={{ minWidth: '260px' }}>
          <QuestionStatus statuses={questionStatuses} />
        </StyledTableCell>
        <StyledTableCell align="center">
          {question.status !== DealGPTQuestionStatus.SKIPPED ? (
            isLoadingRescan && currentRescanQuestions.includes(question) ? (
              <CircularProgress size={30} />
            ) : (
              <Button
                disabled={isLoadingRescan || isLoadingProjectRescan}
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRescanQuestion(question.id);
                }}
              >
                Rescan
              </Button>
            )
          ) : (
            <></>
          )}
        </StyledTableCell>
      </StyledTableRow>
    );

    const childElements = isExpanded
      ? question.children?.flatMap((childQuestion) =>
          generateHierarchicalQuestions(childQuestion, level + 1)
        ) || []
      : [];

    return [questionElement, ...childElements];
  };

  return (
    <CategoryViewContainer maxWidth="lg">
      <StyledButton
        disabled={isLoadingRescan || isLoadingProjectRescan}
        variant="contained"
        onClick={() => handleRescanQuestion()}
      >
        Rescan Category
      </StyledButton>
      <TableContainer
        sx={{
          overflowX: 'auto',
          maxWidth: '1200px',
        }}
      >
        <Table
          id={'category-questions-container'}
          stickyHeader
          size="small"
          sx={{ border: '1px solid rgb(244, 244, 244)' }}
        >
          <TableHead>
            <TableRow sx={{ th: { borderBottom: '2px solid rgb(244, 244, 244)' } }}>
              <StyledTableHeaderCell>Question</StyledTableHeaderCell>
              <StyledTableHeaderCell>Source(s)</StyledTableHeaderCell>
              <StyledTableHeaderCell align="right">Status</StyledTableHeaderCell>
              <StyledTableHeaderCell align="right"></StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questions.flatMap((question) => generateHierarchicalQuestions(question))}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        {flattendQuestions &&
          flattendQuestions.map((question) => (
            <Drawer
              open={question.id === openedQuestionId}
              anchor="right"
              onClose={handleCloseQuestion}
              key={question.id}
            >
              <QuestionView
                gleanAppId={gleanAppId}
                setQuestions={setQuestions}
                questions={flattendQuestions}
                questionsTree={questions}
                question={question}
                handleCloseQuestion={handleCloseQuestion}
              />
            </Drawer>
          ))}
      </div>
    </CategoryViewContainer>
  );
};

export default CategoryView;
