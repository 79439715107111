import { Add } from '@mui/icons-material';
import { Button, styled, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { softDeleteQuestion } from '../../../services/dealGPT';
import type { PageInfo, ProjectRunAnalysisQuestion } from '../../../Types/dealGPT';
import { AnalysisQuestionType } from '../../../Types/enums';
import { FeedbackInterface } from '../../../Types/feedback';
import KPI from './KPIs';
import PromptAnswer from './PromptAnswer';
import QuestionAnswer from './QuestionAnswer';
import RubricPage from './Rubric/RubricPage';

const StepPageContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

const Section = styled('div')({
  '&:not(:first-of-type)': {
    borderTop: '1px solid #E9E9E9',
    paddingTop: '20px',
  },
});

const sortQuestions = (questions: ProjectRunAnalysisQuestion[]): ProjectRunAnalysisQuestion[] => {
  return questions.sort((a, b) => {
    // First compare by sequence
    if (a.sequence !== b.sequence) {
      return a.sequence - b.sequence;
    }
    // If sequence is the same, compare by displayOrder (handling undefined cases)
    if (a.displayOrder && b.displayOrder) {
      return a.displayOrder - b.displayOrder;
    }
    // If one of the displayOrders is undefined, place the defined one first
    if (!a.displayOrder) {
      return 1;
    }
    if (!b.displayOrder) {
      return -1;
    }
    // If both are undefined, they are considered equal
    return 0;
  });
};

const getLatestUniqueQuestions = (
  questions: ProjectRunAnalysisQuestion[]
): ProjectRunAnalysisQuestion[] => {
  const latestQuestionsMap: Map<string, ProjectRunAnalysisQuestion> = new Map();
  questions.forEach((question) => {
    const key = `${question.sequence}-${question.displayOrder}`;
    const existingQuestion = latestQuestionsMap.get(key);

    if (
      !existingQuestion ||
      (question.createdAt &&
        existingQuestion &&
        existingQuestion.createdAt &&
        new Date(question.createdAt) > new Date(existingQuestion.createdAt))
    ) {
      latestQuestionsMap.set(key, question);
    }
  });

  return Array.from(latestQuestionsMap.values());
};

interface StepPageProps {
  page: PageInfo;
  initialPageQuestions?: ProjectRunAnalysisQuestion[];
  pageQuestions?: ProjectRunAnalysisQuestion[];
  latestRunId?: string | null;
  setIsLoadingThis: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoadingApplyChanges: React.Dispatch<React.SetStateAction<boolean>>;
  reprocessingTriggered: () => void;
  handleFeedbackSubmit: (feedback: FeedbackInterface, projectAnalysisQuestionId: string) => void;
}

const StepPage: React.FC<StepPageProps> = ({
  page,
  initialPageQuestions,
  pageQuestions,
  latestRunId,
  setIsLoadingThis,
  setIsLoadingApplyChanges,
  reprocessingTriggered,
  handleFeedbackSubmit,
}) => {
  const [responses, setResponses] = useState<ProjectRunAnalysisQuestion[]>([]);
  const [allVersions, setAllVersions] = useState<ProjectRunAnalysisQuestion[]>([]);
  const [highestQuestionIndex, setHighestQuestionIndex] = useState<number>(1);
  const [suggestSaveOrProcessAll, setSuggestSaveOrProcessAll] = useState<'save' | 'process' | null>(
    null
  );
  const [expandAllKpis, setExpandAllKpis] = useState<boolean>(false);
  const [expandAllRubric, setExpandAllRubric] = useState<boolean>(false);

  const handleAddQuestion = useCallback(() => {
    if (initialPageQuestions) {
      const newQuestion: ProjectRunAnalysisQuestion = { ...initialPageQuestions[0] };
      newQuestion.displayOrder = highestQuestionIndex + 1;
      // console.log('newQuestion', newQuestion);
      setResponses([...responses, newQuestion]);
      setHighestQuestionIndex(highestQuestionIndex + 1);
    }
  }, [responses, highestQuestionIndex, initialPageQuestions]);

  const handleDeleteQuestion = async (questionIndex: number) => {
    const deletedQuestion = responses[questionIndex];

    // console.log('deletedQuestion', deletedQuestion);

    if (latestRunId && deletedQuestion.response) {
      const isDeleted = await softDeleteQuestion(deletedQuestion);
      if (isDeleted) {
        reprocessingTriggered();
      }
    } else {
      setResponses(responses.filter((_, index) => index !== questionIndex));
    }
  };

  const toggleCollapseExpandAllKpis = () => {
    setExpandAllKpis(!expandAllKpis);
  };

  const toggleCollapseExpandAllRubric = () => {
    setExpandAllRubric(!expandAllRubric);
  };

  const processThisPageButton = useMemo(
    () => (
      <Tooltip
        title={
          <div style={{ whiteSpace: 'pre-line' }}>
            {
              "Generate or regenerate an LLM output for every question on this page!\n\nDon't worry, you can go back to a previous version."
            }
          </div>
        }
      >
        <Button
          variant="outlined"
          onClick={async () => {
            setSuggestSaveOrProcessAll('process');
            await new Promise((resolve) => setTimeout(resolve, 1000));
            setSuggestSaveOrProcessAll(null);
          }}
        >
          Generate This Page
        </Button>
      </Tooltip>
    ),
    [setSuggestSaveOrProcessAll]
  );

  useEffect(() => {
    if (responses.length === 0) {
      handleAddQuestion();
    }
  }, [responses, handleAddQuestion]);

  useEffect(() => {
    // console.log('initial page questions', initialPageQuestions);
    // console.log('page questions', pageQuestions);

    // Clear these out when switching pages
    setResponses([]);
    setAllVersions([]);

    if (pageQuestions && pageQuestions.length > 0) {
      // console.log('all versions', page.name, pageQuestions);
      setAllVersions(pageQuestions);

      const sortedUniqueQuestions = sortQuestions(getLatestUniqueQuestions(pageQuestions));
      // console.log('sortedUniqueQuestions', sortedUniqueQuestions);
      setResponses(sortedUniqueQuestions);
      if (
        Number(sortedUniqueQuestions[sortedUniqueQuestions.length - 1].displayOrder) &&
        sortedUniqueQuestions[0].type === AnalysisQuestionType.QUESTION_AND_ANSWER
      ) {
        setHighestQuestionIndex(
          Number(sortedUniqueQuestions[sortedUniqueQuestions.length - 1].displayOrder)
        );
      }
    } else if (
      initialPageQuestions &&
      initialPageQuestions.length > 0 &&
      initialPageQuestions[0].type === AnalysisQuestionType.QUESTION_AND_ANSWER
    ) {
      const sortedQuestions = sortQuestions(initialPageQuestions);
      // This should always have a displayOrder of 1
      setResponses([sortedQuestions[0]]);
    }
  }, [initialPageQuestions, pageQuestions, page.name]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <StepPageContainer>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h2" sx={{ fontSize: '24px' }}>
            {page.name}
          </Typography>
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          {responses &&
            responses.some(
              (response) => response.type === AnalysisQuestionType.QUESTION_AND_ANSWER
            ) &&
            processThisPageButton}
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          {responses &&
            responses.some((response) => response.type === AnalysisQuestionType.KPI) && (
              <Typography
                sx={{
                  fontSize: '14px',
                  color: '#1979CD',
                  cursor: 'pointer',
                  marginBottom: '16px',
                  alignSelf: 'flex-end',
                }}
                onClick={toggleCollapseExpandAllKpis}
              >
                {expandAllKpis ? 'Collapse all' : 'Expand all'}
              </Typography>
            )}
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          {responses &&
            responses.some((response) => response.type === AnalysisQuestionType.RUBRIC) && (
              <Typography
                sx={{
                  fontSize: '14px',
                  color: '#1979CD',
                  cursor: 'pointer',
                  marginBottom: '16px',
                  alignSelf: 'flex-end',
                }}
                onClick={toggleCollapseExpandAllRubric}
              >
                {expandAllRubric ? 'Collapse all' : 'Expand all'}
              </Typography>
            )}
        </div>
        <div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            {responses &&
              page.name !== 'Rubric' &&
              responses.map((response, index) => {
                if (response.type === AnalysisQuestionType.QUESTION_AND_ANSWER) {
                  return (
                    <Section key={`${response.pageName}-${response.promptType}-${index}`}>
                      <QuestionAnswer
                        index={index}
                        responseObject={response}
                        versions={allVersions.filter(
                          (v) =>
                            v.promptType === response.promptType &&
                            v.sequence === response.sequence &&
                            v.displayOrder === response.displayOrder
                        )}
                        setIsLoadingThis={setIsLoadingThis}
                        setIsLoadingApplyChanges={setIsLoadingApplyChanges}
                        reprocessingTriggered={reprocessingTriggered}
                        handleDelete={handleDeleteQuestion}
                        suggestSaveOrProcessAll={suggestSaveOrProcessAll}
                        setSuggestSaveOrProcessAll={setSuggestSaveOrProcessAll}
                        handleFeedbackSubmit={handleFeedbackSubmit}
                      />
                    </Section>
                  );
                } else if (response.type === AnalysisQuestionType.PROMPT_ANSWER) {
                  return (
                    <Section key={`${response.pageName}-${response.promptType}-${index}`}>
                      <PromptAnswer
                        versions={allVersions.filter(
                          (v) =>
                            v.promptType === response.promptType &&
                            v.sequence === response.sequence &&
                            v.displayOrder === response.displayOrder
                        )}
                        textfieldName={`PA-response-${index}`}
                        setIsLoadingThis={setIsLoadingThis}
                        setIsLoadingApplyChanges={setIsLoadingApplyChanges}
                        reprocessingTriggered={reprocessingTriggered}
                        timeToProcess={
                          page.name === 'Rubric Summary' ? '5-10 minutes' : '1-2 minutes'
                        }
                        handleFeedbackSubmit={handleFeedbackSubmit}
                      />
                    </Section>
                  );
                } else if (response.type === AnalysisQuestionType.KPI) {
                  return (
                    <Section key={`${response.pageName}-${response.promptType}-${index}`}>
                      <KPI
                        versions={allVersions.filter(
                          (v) =>
                            v.promptType === response.promptType &&
                            v.sequence === response.sequence &&
                            v.displayOrder === response.displayOrder
                        )}
                        textfieldName={`KPI-response-${index}`}
                        setIsLoadingThis={setIsLoadingThis}
                        setIsLoadingApplyChanges={setIsLoadingApplyChanges}
                        reprocessingTriggered={reprocessingTriggered}
                        expandAll={expandAllKpis}
                        handleFeedbackSubmit={handleFeedbackSubmit}
                      />
                    </Section>
                  );
                }
              })}
            {responses?.some(
              (response) => response.type === AnalysisQuestionType.QUESTION_AND_ANSWER
            ) && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '16px',
                }}
              >
                <Tooltip
                  title={<div style={{ whiteSpace: 'pre-line' }}>{'Add another question'}</div>}
                >
                  <Button variant="outlined" onClick={handleAddQuestion} sx={{ width: '100px' }}>
                    <Add /> Add
                  </Button>
                </Tooltip>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                  {processThisPageButton}
                </div>
              </div>
            )}
            {responses && page.name.includes('Rubric') && page.name !== 'Rubric Summary' && (
              <RubricPage
                pageQuestions={pageQuestions}
                reprocessingTriggered={reprocessingTriggered}
                setIsLoadingThis={setIsLoadingThis}
                setIsLoadingApplyChanges={setIsLoadingApplyChanges}
                expandAll={expandAllRubric}
                handleFeedbackSubmit={handleFeedbackSubmit}
              />
            )}
          </div>
        </div>
      </StepPageContainer>
    </div>
  );
};

export default StepPage;
