import CloseIcon from '@mui/icons-material/Close';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  styled,
  Typography,
} from '@mui/material';
import React from 'react';

type FilterProps = {
  selected?: boolean;
};

const Filter = styled(Typography)<FilterProps>(({ selected = false }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '125%',
  color: '#092B49',
  backgroundColor: selected ? '#CED7E6' : '#E8EEF8',
  borderRadius: '100px',
  padding: '8px 16px',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
}));

const DialogContainer = styled(Container)(({ theme }) => ({
  marginTop: '8px',
  marginBottom: '24px',

  [theme.breakpoints.up('sm')]: {
    padding: '0px',
  },
}));

const FiltersContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '10px',
  margin: '0 15px 10px 15px',
});

export type ArrayFilteredDialogProps = {
  filters: string[];
  openDialog: boolean;
  title: string;
  buttonConfirmText: string;
  isLoading: boolean;
  selectedLength: number;
  isSelectAllFilter: boolean;
  handleCloseDialog: () => void;
  handleConfirmDialog: () => void;
  isFilterSelected: (filter: string) => boolean;
  toggleFilter: (filter: string) => void;
  toggleSelectAllFilter: () => void;
};

const ArrayFilteredDialog = ({
  filters,
  openDialog,
  title,
  buttonConfirmText,
  isLoading,
  selectedLength,
  isSelectAllFilter,
  handleCloseDialog,
  handleConfirmDialog,
  isFilterSelected,
  toggleFilter,
  toggleSelectAllFilter,
}: ArrayFilteredDialogProps) => {
  return (
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>{title}</DialogTitle>
      <Button
        sx={{
          width: 'fit-content',
          marginRight: '15px',
          alignSelf: 'flex-end',
        }}
        onClick={toggleSelectAllFilter}
      >
        {isSelectAllFilter ? 'Select' : 'Clear'} all
      </Button>
      <IconButton
        onClick={handleCloseDialog}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: '#757575',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContainer maxWidth="lg" sx={{ marginBottom: '0px ', minWidth: '270px' }}>
        <FiltersContainer>
          {isLoading ? (
            <CircularProgress sx={{ margin: 'auto' }} />
          ) : (
            filters.map((filter) => (
              <Filter
                key={filter}
                selected={isFilterSelected(filter)}
                onClick={() => toggleFilter(filter)}
              >
                {filter}
                {isFilterSelected(filter) && <CloseOutlinedIcon sx={{ fontSize: '16px' }} />}
              </Filter>
            ))
          )}
        </FiltersContainer>
      </DialogContainer>
      <DialogActions>
        <Button variant="outlined" onClick={handleCloseDialog}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={selectedLength == 0 || isLoading}
          onClick={handleConfirmDialog}
        >
          {buttonConfirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArrayFilteredDialog;
