import {
  MenuItem,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Select as RffSelect, TextField } from 'mui-rff';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import supersub from 'remark-supersub';

import { FeedbackContext } from '../../../../constants/feedbackConstants';
import { processQuestion, userEditSave } from '../../../../services/dealGPT';
import type { ProjectRunAnalysisQuestion } from '../../../../Types/dealGPT';
import { ProcessReason, UpdatedReason } from '../../../../Types/enums';
import { FeedbackInterface } from '../../../../Types/feedback';
import formatDate from '../../../../util/dateUtils';
import Feedback from '../../../Feedback';
import { useFeatures } from '../../../Providers/FeatureProvider';
import ReadMoreText from '../../PhaseTwo/ReadMoreText';
import ReferenceDocuments from '../ReferenceDocuments';
import SectionButtons from '../SectionButtons';
import UnprocessedChangesDot from '../UnprocessedChangesDot';

const StyledTableHeaderCell = styled(TableCell)({
  color: '#757575',
  fontWeight: '700',
  textTransform: 'uppercase',
  paddingBottom: '8px',
});

const StyledSelect = styled(RffSelect)({
  paddingRight: '16px',

  '& .MuiSelect-select': {
    padding: '10px',
    color: '#092B49',
    fontSize: '14px',
  },
});

const ReactMarkdownWrapper = styled('div')({
  fontSize: '14px',
  lineHeight: '1.5rem',
  letterSpacing: '0.25px',
  '& > *': {
    fontFamily: 'Noto Sans',
  },

  'a, a:visited, a:hover, a:focus': {
    color: 'inherit',
  },
});

interface RubricRowProps {
  dimensionName: string;
  textfieldName: string;
  selectName: string;
  versions: ProjectRunAnalysisQuestion[];
  reprocessingTriggered: () => void;
  setIsLoadingThis: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoadingApplyChanges: React.Dispatch<React.SetStateAction<boolean>>;
  setDimensionValues: React.Dispatch<
    React.SetStateAction<{
      [dimension: string]: number;
    }>
  >;
  setSectionUnprocessedDownstreamChanges: React.Dispatch<React.SetStateAction<boolean>>;
  handleFeedbackSubmit: (feedback: FeedbackInterface, projectAnalysisQuestionId: string) => void;
}

const RubricRow: React.FC<RubricRowProps> = ({
  dimensionName,
  textfieldName,
  selectName,
  versions,
  reprocessingTriggered,
  setIsLoadingThis,
  setIsLoadingApplyChanges,
  setDimensionValues,
  setSectionUnprocessedDownstreamChanges,
  handleFeedbackSubmit,
}) => {
  const [analysisQuestionData, setAnalysisQuestionData] = useState<ProjectRunAnalysisQuestion>(
    versions[0]
  );
  const [questionVersions, setQuestionVersions] = useState<ProjectRunAnalysisQuestion[]>(versions);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const features = useFeatures();

  // This is used when the user clicks the save button
  const handleSave = useCallback(
    async (
      values: Record<string, string>,
      reason?: UpdatedReason,
      processReason?: ProcessReason
    ) => {
      const tempAnalysisQuestion: ProjectRunAnalysisQuestion = { ...analysisQuestionData };
      tempAnalysisQuestion.value = values[selectName];
      tempAnalysisQuestion.response = values[textfieldName];

      if (reason) {
        tempAnalysisQuestion.updatedReason = reason;
      }

      // console.log('tempAnalysisQuestion', tempAnalysisQuestion);

      let updatedQuestion = tempAnalysisQuestion;
      if (reason === UpdatedReason.USER_EDIT) {
        const updatedResult = await userEditSave(tempAnalysisQuestion);

        if (updatedResult._data) {
          updatedQuestion = updatedResult._data;
          console.log('updatedResult in db', updatedResult._data);
          setAnalysisQuestionData(updatedResult._data);
          enqueueSnackbar('Content saved successfully', { variant: 'success' });
          reprocessingTriggered();
        }
      }

      if (
        tempAnalysisQuestion.updatedReason === UpdatedReason.REPROCESS ||
        processReason === ProcessReason.APPLY_CHANGES
      ) {
        enqueueSnackbar('Your content is being processed', { variant: 'success' });
        setIsLoadingThis(true);
        if (processReason === ProcessReason.APPLY_CHANGES) {
          setIsLoadingApplyChanges(true);
        }
        await processQuestion(updatedQuestion, processReason);
        reprocessingTriggered();
      }
    },
    [
      analysisQuestionData,
      selectName,
      textfieldName,
      reprocessingTriggered,
      setIsLoadingThis,
      setIsLoadingApplyChanges,
    ]
  );

  const onSubmit = async () => {
    // const tempAnalysisQuestion = await handleSave(values, UpdatedReason.REPROCESS);
    // if (tempAnalysisQuestion) {
    //   enqueueSnackbar('Your content is being processed', { variant: 'success' });
    //   await processQuestion(tempAnalysisQuestion);
    //   reprocessingTriggered();
    // }
  };

  const handleVersionChange = useCallback(
    async (value: string) => {
      const version = versions.find((v) => v.id === value);
      // console.log('version', version);
      if (version) {
        setAnalysisQuestionData(version);

        const value = version.value ?? null;
        if (value !== null) {
          setDimensionValues((prev) => ({
            ...prev,
            [dimensionName]: parseInt(value, 10),
          }));
        }
      }
    },
    [versions, dimensionName, setDimensionValues]
  );

  useEffect(() => {
    // console.log('versions updated', versions);
    setQuestionVersions(versions);
    if (versions[0]) {
      handleVersionChange(versions[0].id);
      if (versions[0].unprocessedDownstreamChanges) {
        setSectionUnprocessedDownstreamChanges(true);
      }
    }
  }, [handleVersionChange, versions, setSectionUnprocessedDownstreamChanges]);

  const initialValues = useMemo(
    () => ({
      [selectName]: analysisQuestionData.value,
      [textfieldName]: analysisQuestionData.response,
    }),
    [selectName, textfieldName, analysisQuestionData.value, analysisQuestionData.response]
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <Select
              value={analysisQuestionData.id}
              onChange={(event) => handleVersionChange(event.target.value as string)}
              size="small"
            >
              {questionVersions.map((version) => (
                <MenuItem
                  value={version.id}
                  selected={version.id === analysisQuestionData.id}
                  key={version.id}
                >
                  Generated on {version.createdAt && formatDate(version.createdAt)} |{' '}
                  {version.updatedReason}
                </MenuItem>
              ))}
            </Select>
            {questionVersions.length > 0 && questionVersions[0].unprocessedDownstreamChanges && (
              <UnprocessedChangesDot />
            )}
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableHeaderCell>Dimension</StyledTableHeaderCell>
                <StyledTableHeaderCell sx={{ textAlign: 'center' }}>Rating</StyledTableHeaderCell>
                <StyledTableHeaderCell>Explanation</StyledTableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={2} sx={{ borderBottom: 'none' }}></TableCell>
                <TableCell sx={{ borderBottom: 'none', padding: 0, paddingTop: '10px' }}>
                  <SectionButtons
                    handleSave={handleSave}
                    values={values}
                    hasGenerated={true}
                    handleEdit={setIsEditing}
                    isEditing={isEditing}
                    question={analysisQuestionData}
                  />
                </TableCell>
              </TableRow>
              <TableRow sx={{ verticalAlign: 'top' }}>
                <TableCell
                  sx={{
                    width: '250px',
                    fontWeight: '300',
                    color: '#092B49',
                    borderBottom: 'none',
                    height: '1px',
                  }}
                >
                  <Typography>{dimensionName}</Typography>
                </TableCell>
                <TableCell sx={{ width: '80px', borderBottom: 'none' }}>
                  {isEditing ? (
                    <StyledSelect name={selectName}>
                      {['', '1', '2', '3', '4', '5'].map((value) => (
                        <MenuItem key={value} value={value}>
                          {value || 'N/A'}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  ) : (
                    <div style={{ display: 'inline-flex', verticalAlign: 'top', width: '80px' }}>
                      <Typography
                        sx={{
                          width: '100%',
                          fontSize: '14px',
                          fontWeight: 700,
                          textAlign: 'center',
                        }}
                      >
                        {analysisQuestionData.value || 'N/A'}
                      </Typography>
                    </div>
                  )}
                </TableCell>
                <TableCell sx={{ padding: '0px', paddingRight: '16px', borderBottom: 'none' }}>
                  {isEditing ? (
                    <TextField name={textfieldName} multiline sx={{ marginTop: '10px' }} />
                  ) : (
                    <ReactMarkdownWrapper>
                      <ReactMarkdown remarkPlugins={[remarkGfm, supersub]}>
                        {analysisQuestionData.response || ''}
                      </ReactMarkdown>
                    </ReactMarkdownWrapper>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} sx={{ borderBottom: 'none', paddingBottom: '40px' }}>
                  <ReferenceDocuments documents={versions[0].documents} />
                </TableCell>
              </TableRow>
              {features.deal_gpt_enriched_prompt_display && (
                <TableRow>
                  <TableCell
                    colSpan={3}
                    style={{ paddingTop: 0, paddingBottom: '10px', borderBottom: 'none' }}
                  >
                    <>
                      <Typography sx={{ fontWeight: '700', color: '#092B49' }}>
                        Enriched Prompt:
                      </Typography>
                      <ReadMoreText text={versions[0].enrichedPrompt || ''} maxLength={65} />
                    </>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell colSpan={3} sx={{ borderBottom: 'none', paddingBottom: '20px' }}>
                  <Feedback
                    initialFeedback={
                      analysisQuestionData.analysisQuestionFeedback as FeedbackInterface | undefined
                    }
                    context={FeedbackContext.ANALYSIS_QUESTION}
                    onFeedbackSubmit={(feedback) =>
                      handleFeedbackSubmit(feedback, analysisQuestionData.id)
                    }
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </form>
      )}
    />
  );
};

export default RubricRow;
