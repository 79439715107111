import { EditOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';

import { getNotes, getScopeAreas, saveNotes } from '../../services/dealGPT';
import { ProjectNotes, ProjectScopeAreaType, ProjectType } from '../../Types/dealGPT';
import { RunType } from '../../Types/enums';

type FilterProps = {
  selected?: boolean;
};

const Filter = styled(Typography)<FilterProps>(({ selected = false }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '125%',
  color: '#092B49',
  backgroundColor: selected ? '#CED7E6' : '#E8EEF8',
  borderRadius: '100px',
  padding: '2px 16px',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
}));

const FiltersContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '16px',
  marginTop: '8px',

  [theme.breakpoints.up('sm')]: {
    padding: '0px',
  },
}));

const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    width: '950px',
    maxWidth: '950px',
  },
});

export type ScopeAreasFilterViewProps = {
  project: ProjectType;
  selectedScopes: ProjectScopeAreaType[];
  setSelectedScopes: (scopes: ProjectScopeAreaType[]) => void;
};

const ScopeAreasFilterView = ({
  project,
  selectedScopes,
  setSelectedScopes,
}: ScopeAreasFilterViewProps) => {
  const [projectScopeAreas, setProjectScopeAreas] = useState<ProjectScopeAreaType[]>();
  const [isLoadingScopeAreas, setIsLoadingScopeAreas] = useState(true);
  const [isLoadingNotes, setIsLoadingNotes] = useState(true);
  const [projectNotes, setProjectNotes] = useState<ProjectNotes>();
  const [openNoteDialog, setOpenNoteDialog] = useState<boolean>(false);
  const textAreaRef = useRef<HTMLInputElement>();

  useEffect(() => {
    const fetchScopeAreas = async () => {
      const scopeAreasResponse = await getScopeAreas({
        projectType: project.projectType,
        projectPhase: RunType.ANALYSIS,
        projectId: project.id,
      });
      setProjectScopeAreas(scopeAreasResponse);
      setIsLoadingScopeAreas(false);
    };

    if (!projectScopeAreas) {
      fetchScopeAreas();
    }
  }, [project.id, project.projectType, projectScopeAreas]);

  const isScopeAreaSelected = (scope: ProjectScopeAreaType) => {
    return selectedScopes?.includes(scope);
  };

  const toggleScopeFilter = (scope: ProjectScopeAreaType) => {
    if (isScopeAreaSelected(scope)) {
      setSelectedScopes(selectedScopes.filter((prevScope) => prevScope !== scope));
    } else {
      setSelectedScopes([...selectedScopes, scope]);
    }
  };

  const handleCloseDialog = () => {
    setOpenNoteDialog(false);
  };

  const getUserInput = () => {
    if (textAreaRef.current) {
      return textAreaRef.current.value;
    }
    return '';
  };

  const handleOpenNoteDialog = async (scopeArea: ProjectScopeAreaType) => {
    if (project) {
      try {
        setIsLoadingNotes(true);
        setOpenNoteDialog(true);

        //Fetching Notes for current project and scope Area
        const notes = await getNotes(project.id, scopeArea.scope);
        if (notes._data) {
          delete notes._data.previousVersionId;
          setProjectNotes(notes._data);
        } else {
          setProjectNotes({
            projectId: project.id,
            notes: '',
            scope: scopeArea.scope,
          });
        }
      } catch (error) {
        handleCloseDialog();
      }
      setIsLoadingNotes(false);
    }
  };

  const handleSaveNote = async () => {
    const newNote = projectNotes;
    if (newNote) {
      newNote.notes = getUserInput();
      // Save notes to the database
      const response = await saveNotes(newNote, false);

      if (response?._isSuccess) {
        enqueueSnackbar('Notes saved successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Failed to save notes', { variant: 'error' });
      }
    }
    handleCloseDialog();
  };

  return (
    <FiltersContainer>
      {projectScopeAreas && projectScopeAreas.length > 0 && (
        <Typography
          sx={{
            fontSize: '14px',
            alignSelf: 'center',
          }}
        >
          Include Site Visit Notes:
        </Typography>
      )}
      {isLoadingScopeAreas ? (
        <CircularProgress sx={{ margin: 'auto' }} />
      ) : (
        projectScopeAreas &&
        projectScopeAreas.length > 0 &&
        projectScopeAreas.map((scope) => (
          <Filter
            key={scope.scope}
            selected={isScopeAreaSelected(scope)}
            onClick={() => toggleScopeFilter(scope)}
          >
            {scope.scope}
            <IconButton
              aria-label="Edit"
              onClick={(e) => {
                e.stopPropagation();
                handleOpenNoteDialog(scope);
              }}
              sx={{ padding: '0' }}
            >
              <EditOutlined sx={{ fontSize: '18px' }} />
            </IconButton>
          </Filter>
        ))
      )}
      <StyledDialog open={openNoteDialog} onClose={handleCloseDialog}>
        <DialogTitle>{projectNotes?.scope} notes</DialogTitle>
        <IconButton
          onClick={handleCloseDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#757575',
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {isLoadingNotes ? (
            <CircularProgress sx={{ margin: 'auto' }} />
          ) : (
            <TextField
              inputRef={(ref) => (textAreaRef.current = ref)}
              defaultValue={projectNotes?.notes}
              name="notes"
              multiline
              sx={{ minWidth: '100%', maxWidth: '100%' }}
              rows={4}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ marginBottom: '10px' }}
            variant="contained"
            disabled={isLoadingNotes}
            onClick={handleSaveNote}
          >
            Save
          </Button>
          <Button
            sx={{ marginBottom: '10px', marginRight: '15px' }}
            variant="outlined"
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
        </DialogActions>
      </StyledDialog>
    </FiltersContainer>
  );
};

export default ScopeAreasFilterView;
