import { Container, styled, Typography } from '@mui/material';
import React, { useState } from 'react';

import NavDrawer from '../components/Drawer/NavDrawer';
import NavBar, { NavBarMode } from '../components/NavBar';
import { useIsMobile } from '../util/useIsMobile';

const ReleaseNotesDiv = styled('div')(() => {
  const isMobile = useIsMobile();
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '20px',
    paddingRight: isMobile ? '0' : '200px',
    paddingLeft: isMobile ? '0' : '200px',
  };
});

const ReleaseNotesPageContainer = styled('div')({
  display: 'flex',
  height: '100vh',

  div: {
    position: 'relative',
  },
});

type ContainerProps = {
  open: boolean;
};

const drawerWidth = '350px';

const MainContainer = styled('div')<ContainerProps>(({ open }) => ({
  marginLeft: open ? 'auto' : `-${drawerWidth}`,
  width: `100%`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  zIndex: 1,
  alignItems: 'flex-start',
  height: 'inherit',
}));

const ReleaseNotesList = [
  {
    version: '2.4.0',
    date: '11/22/2024',
    notes: [
      {
        category: 'New Features',
        bullets: [
          'Add new WM Brand colors and logos',
          'Enhanced prompt page with new filters',
          'Intellio® Advantage: Add project type to filter scopes',
          'Intellio® Advantage: Add ability to rescan questions and question categories in discovery',
          'Intellio® Advantage: Add document links in discovery responses',
          'Intellio® Advantage: Add a checkmark to discovery questions that have been validated',
          'Intellio® Advantage: Add in-app feedback on analysis questions',
          'Intellio® Advantage: Add ability to include site visit notes in project chat',
        ],
      },
      {
        category: 'Bug Fixes',
        bullets: [
          'Fix issue where chat input would clear once model finished responding',
          'Fix issue prevent the renaming of Intellio® Advantage conversations',
        ],
      },
    ],
  },
  {
    version: '2.3.0',
    date: '10/25/2024',
    notes: [
      {
        category: 'New Features',
        bullets: [
          'Add new homepage',
          "Add animation and tweaks in celebration of Nigel's 1 year birthday",
          'Add ability to scroll up and interact with messages while response is streaming',
          'Intellio® Advantage: Improve initial load time for projects and discovery',
          'Intellio® Advantage: Update tab titles depending on project',
          'Intellio® Advantage: Add ability to filter and search discovery questions',
          'Intellio® Advantage: Add ability to search projects',
          'Intellio® Advantage: Add in-app feedback on discovery questions',
        ],
      },
    ],
  },
  {
    version: '2.2.0',
    date: '10/11/2024',
    notes: [
      {
        category: 'New Features',
        bullets: [
          'Add ability to use a custom preferred name',
          'Enhance interoperability with copying text out of Nigel and into other applications',
          'Enhance file upload compatibility, speed, and size limits',
          'Add ability to specify prompt template variables',
          'Add pretty formatting for scientific and advanced math equations (TeX)',
          'Add ability to abort message streaming',
          'Add ability to reuse prompt from conversation card',
          'Intellio® Advantage: Add ability to archive projects',
          'Intellio® Advantage: Add ability to validate discovery findings',
        ],
      },
      {
        category: 'General Notes',
        bullets: ['Various styling and usability improvements'],
      },
    ],
  },
  {
    version: '2.1.0',
    date: '09/04/2024',
    notes: [
      {
        category: 'New Features',
        bullets: [
          'Implement Formbricks for in-app feedback',
          'Add ability to share conversations',
          'Intellio® Advantage: Add scope area selection for discovery runs',
          'Intellio® Advantage: Add Expand/Collapse all to KPIs & Rubric questions',
          'Intellio® Advantage: Add support for hierarchical discovery questions',
          'Intellio® Advantage: Optimize discovery runs to decrease scan time',
        ],
      },
      {
        category: 'General Notes',
        bullets: ['Fix bug that hid user messages when a prompt was not editable'],
      },
    ],
  },
  {
    version: '2.0.0',
    date: '08/15/2024',
    notes: [
      {
        category: 'New Features',
        bullets: [
          'Add Intellio® Advantage analysis capabilities',
          'Optimize Intellio® Advantage discovery displays for better performance',
          'Implement Intellio® Advantage scan history and selector',
          'Update UI for Mobile',
          `Add profile picture in chat`,
          'File Upload UI improvements',
          'Downloading a conversation will now include the contents of the files and images uploaded',
          'Added an outage banner to quickly inform users of a known issue with Nigel',
          'Accessibility improvements including improved tabbing',
        ],
      },
      {
        category: 'General Notes',
        bullets: [
          'Fix bug that sometimes allowed for a double message sending',
          'Fix bug that allowed for saving empty prompts',
          'More auth fixes',
        ],
      },
    ],
  },
  {
    version: '1.5.0',
    date: '07/09/2024',
    notes: [
      {
        category: 'New Features',
        bullets: [
          'Add ability to search through previous conversations',
          'Conversations are now given an LLM generated title',
          'Add ability to provide feedback on the most recent AI message in chat',
          'Add ability to upload images and discuss with GPT4o',
          'Larger file uploads',
          'Add ability for model to generate a visual representation of table data',
          'Add a profile settings page to control default llm and temperature',
        ],
      },
      {
        category: 'General Notes',
        bullets: [
          'Created more robust fullstack testing',
          'Created a new demo environment',
          'Implemented database optimizations for faster load times',
          'Fix bug related to profile image not showing',
        ],
      },
    ],
  },
  {
    version: '1.4.2',
    date: '06/03/2024',
    notes: [
      {
        category: 'General Notes',
        bullets: [
          'Optimize Intellio® Advantage project queries for better performance',
          'Add more context and How-To links to Intellio® Advantage homepage and navbar',
          'Implement multiple administrative capabilities for debugging and maintenance regarding Intellio® Advantage',
          'Support new Intellio® Advantage response types and implement a more database driven approach to questions and prompts',
          'Fix bug where Intellio® Advantage free chat was not responding',
        ],
      },
    ],
  },
  {
    version: '1.4.1',
    date: '05/28/2024',
    notes: [
      {
        category: 'New Features',
        bullets: [
          'Add GPT 4o 128k model',
          'Add a "whats new" banner and repurpose homepage card for new features',
        ],
      },
    ],
  },
  {
    version: '1.4.0',
    date: '05/03/2024',
    notes: [
      {
        category: 'New Features',
        bullets: [
          'Add Intellio® Advantage',
          'Add new automatic authentication flow',
          'Add incognito chat mode for high security Nigel use-cases',
        ],
      },
      {
        category: 'General Notes',
        bullets: [
          'Refresh UI, the sidebar is used to navigate between different Nigel partner apps now',
          'Improve accessibility and tab navigation on different screens',
          'Fix authentication timeout issues with new authentication strategy',
          'Fix user profile icon not showing sometimes',
          'Add pagination for conversation history, drastically speeding up navigation for users with many conversations',
        ],
      },
    ],
  },
  {
    version: '1.3.0',
    date: '03/18/2024',
    notes: [
      {
        category: 'New Features',
        bullets: [
          'Add ability to branch a conversation',
          'Add ability to regenerate a model response',
          'Add streaming for function responses',
          'Add file cards to previous chat messages to be able to see previously uploaded files',
        ],
      },
      {
        category: 'General Notes',
        bullets: [
          'Fix some authentication issues',
          'Improve accessibility',
          'Fix the copy prompt URLs',
          'Repair prompt filter logic',
          'Update to newest version of AzureOpenAI',
          'More robust error handling',
          'Add a button to return to the top of the FAQs page',
          'Improve File Upload UX',
        ],
      },
    ],
  },
  {
    version: '1.2.0',
    date: '01/31/2024',
    notes: [
      {
        category: 'New Feature: File upload',
        bullets: [
          'Add ability to upload and chat with multiple files',
          'Add ability to do OCR (optical character recognition) on PDFs',
        ],
      },
      {
        category: 'General Notes',
        bullets: [
          'Add improved context window tracking to conversations',
          'Add date to system prompt so Nigel knows the current date',
          'Made at least one tag required for prompts',
          'Various styling updates',
        ],
      },
    ],
  },
  {
    version: '1.1.3',
    date: '12/14/2023',
    notes: [
      {
        category: 'New Feature: Advanced Prompt Search',
        bullets: [
          'Add ability to search prompts by title, description, and tags',
          'Add ability to sort by likes and usage',
          'Add pagination',
        ],
      },
      {
        category: 'General Notes',
        bullets: [
          'GPT 4 32k is now the default model, with the ability to switch back to 3.5 Turbo 16k if you want to',
          'Add new filters for which model prompts work best on',
          'New open/close icon for sidebar on Chat page',
          'Small bug fixes and UI improvements',
        ],
      },
    ],
  },
  {
    version: '1.1.2',
    date: '12/01/2023',
    notes: [
      {
        category: 'New Feature: Table Support',
        bullets: [
          'Add nicely formatted table support to responses, just ask Nigel to format its response in a table',
          'Add ability to copy table to csv',
          'Add ability to copy table to clipboard',
        ],
      },
      {
        category: 'General Notes',
        bullets: [
          'Update homepage icons',
          'Add Entra User Icon. If you have a profile picture associated with your WM account, it will show up in the top right corner',
          'Sidebar automatically closes when screen width is small',
          'Add admin dashboard',
        ],
      },
      {
        category: 'Bug Fixes',
        bullets: [
          'Fix issue where drawer button would sometimes disappear',
          'Removed markdown formatting on user sent messages',
          'Prompt loading skeleton width was too small',
          'Fix FAQ typos',
          'Other small usability fixes',
        ],
      },
    ],
  },
  {
    version: '1.1.1',
    date: '11/08/2023',
    notes: [
      {
        category: 'General Notes',
        bullets: ['New login page'],
      },
      {
        category: 'Bug Fixes',
        bullets: [`Fixed issue where published confirmation showed up when it shouldn't`],
      },
    ],
  },
  {
    version: '1.1.0',
    date: '11/02/2023',
    notes: [
      {
        category: 'New Feature: My Prompts',
        bullets: [
          'Added My Prompts page',
          'Ability to create prompts',
          'Ability to publish self-created prompts',
          'Ability to like prompts',
          'Ability to pin prompts',
          'Ability to set prompts as featured',
          'Ability to copy public prompts to your collection',
          'Added My Prompts product tour',
        ],
      },
      {
        category: 'General Notes',
        bullets: ['Animations are smoother', 'New logo'],
      },
    ],
  },
  {
    version: '1.0.0',
    date: '10/20/2023',
    notes: [
      {
        category: 'General Notes',
        bullets: ['Nigel Launched firm-wide'],
      },
    ],
  },
];

const ReleaseNotes = (): JSX.Element => {
  const [historyDrawerOpen, setHistoryDrawerOpen] = useState<boolean>(
    window.matchMedia('(min-width:960px)').matches
  );

  return (
    <ReleaseNotesPageContainer>
      <NavDrawer
        drawerWidth={drawerWidth}
        drawerOpen={historyDrawerOpen}
        setDrawerOpen={setHistoryDrawerOpen}
      />
      <MainContainer open={historyDrawerOpen}>
        <NavBar mode={NavBarMode.NIGEL} />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            overflowY: 'auto',
            height: '100%',
            width: '100%',
          }}
        >
          <Container sx={{ bgcolor: 'white', height: 'fit-content', margin: '0px' }}>
            <ReleaseNotesDiv>
              <Typography
                variant="h4"
                sx={{ fontWeight: 700, marginBottom: '20px', color: '#F900D3' }}
              >
                Release Notes
              </Typography>
              {ReleaseNotesList.map(({ version, date, notes }, index: number) => {
                return (
                  <div key={index} style={{ marginBottom: '40px', color: '#070154' }}>
                    <Typography
                      sx={{
                        fontSize: '24px',
                        fontWeight: '700',
                        marginBottom: '10px',
                      }}
                    >
                      <span style={{ paddingRight: '10px' }}>v.{version}</span> {date}
                    </Typography>

                    {notes.map(({ category, bullets }, index2: number) => {
                      return (
                        <div key={index2}>
                          <Typography variant="subtitle1" sx={{ fontSize: '20px' }}>
                            {category}
                          </Typography>
                          <ul key={index2} style={{ marginTop: '5px' }}>
                            {bullets.map((bullet, index3: number) => {
                              return (
                                <li key={index3}>
                                  <Typography>{bullet}</Typography>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </ReleaseNotesDiv>
          </Container>
        </div>
      </MainContainer>
    </ReleaseNotesPageContainer>
  );
};

export default ReleaseNotes;
