import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useProjects } from '../Providers/ProjectsProvider';

const appendEnvironment = (title: string) => {
  let newTitle = title;
  if (import.meta.env.VITE_ENVIRONMENT === 'demo') {
    newTitle = `${title} - demo`;
  } else if (import.meta.env.VITE_ENVIRONMENT === 'staging') {
    newTitle = `${title} - staging`;
  } else if (import.meta.env.VITE_ENVIRONMENT === 'dev') {
    newTitle = `${title} - dev`;
  } else if (import.meta.env.VITE_ENVIRONMENT === 'local') {
    newTitle = `${title} - local`;
  }
  return newTitle;
};

export interface setPageTitleProps {
  projectId?: string;
  projectName?: string;
}

export function setPageTitle(props?: setPageTitleProps) {
  const titleElement = document.getElementById('title');
  const iconElement = document.getElementById('icon') as HTMLLinkElement;
  if (titleElement) {
    if (
      import.meta.env.VITE_EXPERTS_REDIRECT_ORIGIN_URI &&
      window.location.origin === import.meta.env.VITE_EXPERTS_REDIRECT_ORIGIN_URI
    ) {
      titleElement.innerText = 'Experts';
    } else {
      if (window.location.pathname.includes('intellio-advantage')) {
        let title = 'Intellio Advantage';
        if (props?.projectId && props?.projectName) {
          title += ` - ${props.projectName}`;
        }
        titleElement.innerText = appendEnvironment(title);
      } else {
        titleElement.innerText = appendEnvironment('Nigel');
        if (
          import.meta.env.VITE_ENVIRONMENT !== 'prod' &&
          import.meta.env.VITE_ENVIRONMENT !== 'demo'
        ) {
          iconElement.href = '/favicondev.png';
        }
      }
    }
  }
}

const PageTitle = (): JSX.Element => {
  const location = useLocation();
  const { projects } = useProjects();
  const { projectId } = useParams();

  useEffect(() => {
    const project = projects?.find((p) => p.id === projectId);
    const projectName = project?.name;
    setPageTitle({ projectId, projectName });
  }, [location, projects, projectId]);

  return <></>;
};

export default PageTitle;
