import React, { createContext, useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import type { ArgumentType } from '../../Types/gtag.d';
import { useAuth } from './AuthProvider';

declare global {
  interface Window {
    gtag: (...args: ArgumentType[]) => void;
  }
}

// Note: you MUST prefix any AnalyticsEvents with WM_ so they show up in
// reports and maximum length for new event names is 40 characters.
export enum AnalyticsEvent {
  WM_NIGEL_MESSAGE = 'WM_NIGEL_MESSAGE',
  WM_NIGEL_FILE_UPLOAD = 'WM_NIGEL_FILE_UPLOAD',
  WM_NIGEL_FILE_UPLOAD_EXCEED_SIZE_LIMIT = 'WM_NIGEL_FILE_UPLOAD_EXCEED_SIZE_LIMIT',
  WM_NIGEL_CHAT_DOWNLOAD = 'WM_NIGEL_CHAT_DOWNLOAD',
  WM_NIGEL_REGENERATE_RESPONSE = 'WM_NIGEL_REGENERATE_RESPONSE',
  WM_NIGEL_LLM_CHANGE = 'WM_NIGEL_LLM_CHANGE',
  WM_NIGEL_FEEDBACK_BUTTON_CLICK = 'WM_NIGEL_FEEDBACK_BUTTON_CLICK',
  WM_NIGEL_PAGE_LOAD = 'WM_NIGEL_PAGE_LOAD',
  WM_IA_D_CATEGORY_ACCORDION = 'WM_IA_D_CATEGORY_ACCORDION',
  WM_IA_D_QUESTION_CLICK = 'WM_IA_D_QUESTION_CLICK',
  WM_IA_D_VALIDATE = 'WM_IA_D_VALIDATE',
  WM_IA_D_SOURCE_CLICK = 'WM_IA_D_SOURCE_CLICK',
  WM_IA_D_SHOW_ALL_SOURCES = 'WM_IA_D_SHOW_ALL_SOURCES',
  WM_IA_D_EXPAND_ALL_CATEGORIES = 'WM_IA_D_EXPAND_ALL_CATEGORIES',
  WM_IA_D_QUESTION_FILTER = 'WM_IA_D_QUESTION_FILTER',
  WM_IA_D_RUN_PROJECT = 'WM_IA_D_RUN_PROJECT',
  WM_IA_D_SEARCH_ENTERED = 'WM_IA_D_SEARCH_ENTERED',
  WM_IA_D_RESCAN_QUESTIONS = 'WM_IA_D_RESCAN_QUESTIONS',
}

// HEY!! Are you adding a new property here? Make sure to add it as a Google Analytics Custom Dimension and
// to the report pulling python script in databricks
type AnalyticsEventData = {
  // An ID associated with the event to trace back to our database, if applicable
  id?: string;
  // The value, if applicable
  value?: string;
  // The type related to the event, if applicable
  type?: string;
  // The size related to the event, if applicable
  size?: string;
};

type SessionOptions = {
  appUserId: string;
  appSessionId: string;
};

type SessionStorage = {
  value: SessionOptions;
};

interface AnalyticsContextType {
  event: (eventType: AnalyticsEvent, data?: AnalyticsEventData) => void;
}

interface Props {
  children: React.ReactNode;
}

function googleAnalyticsEvent(
  event: AnalyticsEvent,
  data?: AnalyticsEventData,
  session?: SessionOptions
): void {
  window.gtag('event', event.toString(), {
    ...data,
    ...session,
    timestamp: new Date().toISOString(),
  });
}

export const AnalyticsHelper = (): AnalyticsContextType => {
  function getStorage(): SessionStorage | null {
    const value: string | null = sessionStorage.getItem('_session');
    const result: SessionStorage | null = value ? (JSON.parse(value) as SessionStorage) : null;

    return result;
  }

  function setStorage(userId: string, sessionId: string): SessionStorage {
    const storage: SessionStorage = {
      value: {
        appUserId: userId,
        appSessionId: sessionId,
      },
    };
    sessionStorage.setItem('_session', JSON.stringify(storage));

    googleAnalyticsEvent(AnalyticsEvent.WM_NIGEL_PAGE_LOAD, undefined, storage.value);

    return storage;
  }

  const [session, setSession] = useState<SessionOptions>();
  const { initialized, currentUser } = useAuth();

  useEffect(() => {
    if (!initialized) {
      return;
    }

    const userId: string = currentUser?.id || '';

    let storageData = getStorage();
    let sessionId = '';

    if (!storageData || storageData.value.appSessionId === '') {
      sessionId = uuidv4();
    } else {
      sessionId = storageData.value.appSessionId;
    }
    storageData = setStorage(userId, sessionId);
    setSession(storageData.value);
  }, [initialized, currentUser?.id]);

  const contextType: AnalyticsContextType = {
    event: (event: AnalyticsEvent, data?: AnalyticsEventData): void => {
      googleAnalyticsEvent(event, data, session);
    },
  };

  return contextType;
};

const AnalyticsContext: React.Context<AnalyticsContextType | null> =
  createContext<AnalyticsContextType | null>(null);

export const AnalyticsProvider = ({ children }: Props): React.ReactElement => {
  const analyticsContextType = AnalyticsHelper();

  return (
    <AnalyticsContext.Provider value={analyticsContextType}>{children}</AnalyticsContext.Provider>
  );
};

export const useAnalytics = (): AnalyticsContextType => {
  const analyticsContext = useContext(AnalyticsContext);

  if (!analyticsContext) {
    throw new Error('useAnalytics must be used within a AnalyticsProvider');
  }

  return analyticsContext;
};
