import { OpenInNew } from '@mui/icons-material';
import { styled, Typography } from '@mui/material';
import React from 'react';

import NigelWrapped from '../../../assets/nigel-wrapped.png';

const StyledLink = styled('a')({
  color: 'inherit',
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'underline',
  },
});

const OpenInNewIcon = (
  <OpenInNew sx={{ fontSize: '20px', marginRight: '2px', verticalAlign: 'text-top' }} />
);

const Announcements: React.FC = () => {
  return (
    <>
      <Typography
        variant="subtitle2"
        sx={{
          height: '20px',
          color: '#0047FF',
          margin: 0,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        NIGEL 2024 Wrapped
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
        <div style={{ height: '30%', marginBottom: '10px' }}>
          <Typography variant="body2">
            🏢 Ready to see the Nigel 2024 Wrapped Video? <br />
            Click{' '}
            <StyledLink
              href={`https://westmonroepartners1.sharepoint.com/sites/AILab/_layouts/15/stream.aspx?id=%2Fsites%2FAILab%2FAI%20Lab%20Assets%2FNTK%20Videos%2FNigel%20Wrapped%202024%2Emp4&ga=1&referrer=StreamWebApp%2EWeb&referrerScenario=AddressBarCopied%2Eview%2E688dd6d3%2Df7b8%2D4ecf%2Dbc89%2D3eeec1fe4318`}
              target="_blank"
            >
              {OpenInNewIcon}
              <b>HERE</b>
            </StyledLink>{' '}
            to see how the <b>company</b> used Nigel this year.
          </Typography>
        </div>
        <div style={{ height: '30%' }}>
          <Typography variant="body2">
            🫵 Ready to see your personalized Nigel 2024 Wrapped? <br />
            Click{' '}
            <StyledLink
              href={`https://app.powerbi.com/groups/363a1305-c15e-49fa-b825-497bf80247db/reports/7c33c3d1-728b-411a-9c5f-d1854718b190/4a9304956704b85b22de?experience=power-bi&bookmarkGuid=6533d137d8a767bba20a`}
              target="_blank"
            >
              {OpenInNewIcon}
              <b>HERE</b>
            </StyledLink>{' '}
            to see how <b>you</b> used Nigel this year.
          </Typography>
        </div>
        <div
          style={{
            height: '120px',
            marginTop: '10px',
            marginBottom: '10px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img src={NigelWrapped} style={{ height: 'inherit' }} />
        </div>
      </div>
    </>
  );
};

export default Announcements;
