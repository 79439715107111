import { Box, styled } from '@mui/material';
import React from 'react';
import Carousel from 'react-material-ui-carousel';

import Announcements from './Announcements';
import FeatureHighlights from './FeatureHighlights';
import Trainings from './Trainings';
import UserSpecificStats from './UserSpecificStats';

const slides = [
  <Announcements key={'announcements'} />,
  <Trainings key={'trainings'} />,
  <FeatureHighlights key={'feature_highlights'} />,
  <UserSpecificStats key={'user_specific_stats'} />,
];

const InfoCard = styled(Box)(() => {
  return {
    backgroundColor: 'white',
    minHeight: '300px',
    textAlign: 'start',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '0',
    marginLeft: '0',
    maxHeight: '300px',
    borderRadius: '8px',
    padding: '5px 50px',
  };
});

const CarouselViewer = (): JSX.Element => {
  return (
    <div className="ignore-position">
      <Box
        sx={{
          position: 'static',
          overflow: 'hidden',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
        }}
      >
        <Carousel
          autoPlay={false}
          animation="slide"
          navButtonsAlwaysVisible
          indicators
          swipe={false}
          duration={1000}
          interval={10000}
          navButtonsProps={{
            style: {
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              color: '#000',
            },
          }}
          indicatorContainerProps={{
            style: {
              position: 'absolute',
              bottom: '16px',
              zIndex: 1,
              marginTop: 0,
            },
          }}
          indicatorIconButtonProps={{
            style: {
              padding: '3px',
              color: '#070154',
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              color: '#0047FF',
            },
          }}
          sx={{
            minWidth: '500px',
            borderRadius: '8px',
          }}
        >
          {slides.map((slide, index) => (
            <InfoCard key={index} sx={{ bgcolor: '#E8EEF8' }}>
              {slide}
            </InfoCard>
          ))}
        </Carousel>
      </Box>
    </div>
  );
};

export default CarouselViewer;
