import { styled, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link

export type ReusePromptBannerProp = {
  promptId: string;
};

const ReusePromptBannerContainer = styled('div')({
  alignItems: 'center',
  borderLeft: '4px solid #F900D3',
  paddingLeft: '0.5em',
  marginTop: '5px',
});

const ReusePromptBanner = ({ promptId }: ReusePromptBannerProp) => (
  <ReusePromptBannerContainer>
    <Typography
      sx={{
        fontSize: '14px',
        fontWeight: '400',
        color: '#757575',
        lineHeight: '1.2em',
      }}
    >
      Looks like you&apos;re using a prompt! Click{' '}
      <Link
        to={`/chat?prompt=${promptId}`}
        style={{ textDecoration: 'underline', color: '#0047FF' }}
      >
        here
      </Link>{' '}
      to reuse this prompt and start a new conversation.
    </Typography>
  </ReusePromptBannerContainer>
);

export default ReusePromptBanner;
