import ChevronRightSharpIcon from '@mui/icons-material/ChevronRightSharp';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Select,
  styled,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import { TextField } from 'mui-rff';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import supersub from 'remark-supersub';

import { FeedbackContext } from '../../../constants/feedbackConstants';
import { processQuestion, userEditSave } from '../../../services/dealGPT';
import type { ProjectRunAnalysisQuestion } from '../../../Types/dealGPT';
import { ProcessReason, UpdatedReason } from '../../../Types/enums';
import { FeedbackInterface } from '../../../Types/feedback';
import formatDate from '../../../util/dateUtils';
import Feedback from '../../Feedback';
import { useFeatures } from '../../Providers/FeatureProvider';
import ReadMoreText from './ReadMoreText';
import ReferenceDocuments from './ReferenceDocuments';
import SectionButtons from './SectionButtons';
import UnprocessedChangesDot from './UnprocessedChangesDot';

const KPIAccordionSummary = styled(AccordionSummary)({
  flexDirection: 'row-reverse',
  paddingLeft: 0,

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },

  '& .MuiAccordionSummary-content': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '24px',
  },
});

const ReactMarkdownWrapper = styled('div')({
  fontSize: '14px',
  lineHeight: '1.5rem',
  letterSpacing: '0.25px',
  '& > *': {
    fontFamily: 'Noto Sans',
  },

  'a, a:visited, a:hover, a:focus': {
    color: 'inherit',
  },
});

const StyledSelect = styled(Select)({
  '& .MuiSelect-select': {
    padding: '10px',
    color: '#092B49',
  },
});
interface KPIProps {
  versions: ProjectRunAnalysisQuestion[];
  textfieldName: string;
  setIsLoadingThis: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoadingApplyChanges: React.Dispatch<React.SetStateAction<boolean>>;
  reprocessingTriggered: () => void;
  expandAll: boolean;
  handleFeedbackSubmit: (feedback: FeedbackInterface, projectAnalysisQuestionId: string) => void;
}

const KPI: React.FC<KPIProps> = ({
  versions,
  textfieldName,
  setIsLoadingThis,
  setIsLoadingApplyChanges,
  reprocessingTriggered,
  expandAll,
  handleFeedbackSubmit,
}) => {
  const [analysisQuestionData, setAnalysisQuestionData] = useState<ProjectRunAnalysisQuestion>(
    versions[0]
  );
  const [questionVersions, setQuestionVersions] = useState<ProjectRunAnalysisQuestion[]>(versions);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [expandAccordion, setExpandAccordion] = useState<boolean>(expandAll);

  // This is used when the user clicks the save button
  const handleSave = async (
    values: Record<string, string>,
    reason?: UpdatedReason,
    processReason?: ProcessReason
  ) => {
    const tempAnalysisQuestion: ProjectRunAnalysisQuestion = { ...analysisQuestionData };
    tempAnalysisQuestion.response = values[textfieldName];
    tempAnalysisQuestion.value = values['value'];

    if (reason) {
      tempAnalysisQuestion.updatedReason = reason;
    }

    // console.log('tempAnalysisQuestion', tempAnalysisQuestion);

    let updatedQuestion = tempAnalysisQuestion;
    if (reason === UpdatedReason.USER_EDIT) {
      const updatedResult = await userEditSave(tempAnalysisQuestion);

      if (updatedResult._data) {
        updatedQuestion = updatedResult._data;
        console.log('updatedResult in db', updatedResult._data);
        setAnalysisQuestionData(updatedResult._data);
        enqueueSnackbar('Content saved successfully', { variant: 'success' });
        reprocessingTriggered();
      }
    }

    if (
      tempAnalysisQuestion.updatedReason === UpdatedReason.REPROCESS ||
      processReason === ProcessReason.APPLY_CHANGES
    ) {
      enqueueSnackbar('Your content is being processed', { variant: 'success' });
      setIsLoadingThis(true);
      if (processReason === ProcessReason.APPLY_CHANGES) {
        setIsLoadingApplyChanges(true);
      }
      await processQuestion(updatedQuestion, processReason);
      reprocessingTriggered();
    }
  };

  const onSubmit = async () => {
    // setIsLoading(true);
    // enqueueSnackbar('Your content is being processed', { variant: 'success' });
    // const tempAnalysisQuestion = await handleSave(
    //   analysisQuestionData as unknown as Record<string, string>,
    //   UpdatedReason.REPROCESS
    // );
    // if (tempAnalysisQuestion) {
    //   await processQuestion(tempAnalysisQuestion);
    //   reprocessingTriggered();
    // }
  };

  const handleVersionChange = useCallback(
    async (value: string) => {
      const version = versions.find((v) => v.id === value);
      // console.log('version', version);
      if (version) {
        setAnalysisQuestionData(version);
      }
    },
    [versions]
  );

  const toggleAccordion = () => {
    setExpandAccordion(!expandAccordion);
  };

  useEffect(() => {
    setExpandAccordion(expandAll);
  }, [expandAll]);

  useEffect(() => {
    // console.log('versions updated:', formatDate(versions[0].createdAt));
    setQuestionVersions(versions);
    handleVersionChange(versions[0].id);
  }, [handleVersionChange, versions]);

  const features = useFeatures();
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        [textfieldName]: analysisQuestionData.response,
        ['value']: analysisQuestionData.value,
      }}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Accordion
            square
            disableGutters
            sx={{ boxShadow: 'none' }}
            expanded={expandAccordion}
            onChange={toggleAccordion}
          >
            <KPIAccordionSummary expandIcon={<ChevronRightSharpIcon />}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '60%' }}>
                <Typography sx={{ fontWeight: '700', color: '#092B49' }}>
                  {analysisQuestionData.prettyQuestion}
                </Typography>

                <Typography sx={{ fontStyle: 'italic', color: '#092B49' }}>
                  {analysisQuestionData.area} - {analysisQuestionData.dimension}
                </Typography>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                  {!isEditing ? (
                    <Typography sx={!analysisQuestionData.value ? { color: 'grey' } : {}}>
                      {analysisQuestionData.value ? analysisQuestionData.value : 'N/A'}
                    </Typography>
                  ) : analysisQuestionData.lookupList ? (
                    <StyledSelect
                      value={analysisQuestionData.value}
                      onChange={(event) => {
                        setAnalysisQuestionData({
                          ...analysisQuestionData,
                          value: event.target.value as string,
                        });
                      }}
                      onClick={(event) => event.stopPropagation()}
                      sx={{ minWidth: '160px' }}
                    >
                      {(JSON.parse(analysisQuestionData.lookupList ?? '') as string[]).map(
                        (value) => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        )
                      )}
                    </StyledSelect>
                  ) : (
                    <MuiTextField
                      InputProps={{
                        inputProps: {
                          style: { textAlign: 'right' },
                        },
                      }}
                      size="small"
                      value={analysisQuestionData.value}
                      onChange={(event) => {
                        setAnalysisQuestionData({
                          ...analysisQuestionData,
                          value: event.target.value as string,
                        });
                      }}
                      onClick={(event) => event.stopPropagation()}
                      sx={{ maxWidth: '10rem', minWidth: '160px' }}
                    />
                  )}
                  {questionVersions.length > 0 &&
                    questionVersions[0].unprocessedDownstreamChanges && (
                      <UnprocessedChangesDot position={{ right: '-20px' }} />
                    )}
                </div>
                <Typography
                  variant="caption"
                  sx={!analysisQuestionData.value ? { color: 'red' } : { color: 'orange' }}
                >
                  {!analysisQuestionData.value
                    ? 'No Answer Found'
                    : 'Answer Found in Content, Must Validate'}
                </Typography>
              </div>
            </KPIAccordionSummary>
            <div>
              <AccordionDetails>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '24px',
                    marginBottom: '24px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '700',
                      color: '#757575',
                    }}
                  >
                    RESPONSE
                  </Typography>
                  <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <StyledSelect
                      value={analysisQuestionData.id}
                      onChange={(event) => handleVersionChange(event.target.value as string)}
                    >
                      {questionVersions.map((version) => (
                        <MenuItem
                          key={version.id}
                          value={version.id}
                          selected={version.id === analysisQuestionData.id}
                        >
                          Generated on {version.createdAt && formatDate(version.createdAt)} |{' '}
                          {version.updatedReason}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                    {questionVersions.length > 0 &&
                      questionVersions[0].unprocessedDownstreamChanges && <UnprocessedChangesDot />}
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                  <SectionButtons
                    handleSave={handleSave}
                    values={values}
                    hasGenerated={true}
                    handleEdit={setIsEditing}
                    isEditing={isEditing}
                    question={analysisQuestionData}
                  />
                  {isEditing ? (
                    <TextField name={textfieldName} multiline />
                  ) : (
                    <ReactMarkdownWrapper>
                      <ReactMarkdown remarkPlugins={[remarkGfm, supersub]}>
                        {analysisQuestionData.response || ''}
                      </ReactMarkdown>
                    </ReactMarkdownWrapper>
                  )}
                </div>
              </AccordionDetails>
            </div>

            <ReferenceDocuments documents={analysisQuestionData.documents} />
            {features.deal_gpt_enriched_prompt_display && (
              <>
                <Typography sx={{ fontWeight: '700', color: '#092B49', marginTop: '20px' }}>
                  Enriched Prompt:
                </Typography>
                <div style={{ marginBottom: '15px' }}>
                  <ReadMoreText text={analysisQuestionData.enrichedPrompt || ''} maxLength={160} />
                </div>
              </>
            )}
            <Feedback
              initialFeedback={
                analysisQuestionData.analysisQuestionFeedback as FeedbackInterface | undefined
              }
              context={FeedbackContext.ANALYSIS_QUESTION}
              onFeedbackSubmit={(feedback) =>
                handleFeedbackSubmit(feedback, analysisQuestionData.id)
              }
            />
          </Accordion>
        </form>
      )}
    />
  );
};

export default KPI;
