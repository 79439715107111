import { PayloadAction } from '@reduxjs/toolkit';
import * as filesaver from 'file-saver';
import { enqueueSnackbar } from 'notistack';
import React, { createRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from 'redux/store';

import {
  deleteConversation,
  downloadFile,
  updateConversation,
} from '../../redux/actions/conversationActions';
import { selectConversations } from '../../redux/reducers/conversationReducer';
import { Conversation, DownloadConversationResponse } from '../../Types/conversation';
import { AppEnum, AppNameBannerMap } from '../../Types/enums';
import DrawerCard from '../Drawer/DrawerCard';
import { AnalyticsEvent, useAnalytics } from '../Providers/AnalyticsProvider';

type ChatCardProps = {
  conversation: Conversation;
  IsSearchFocused: boolean;
  onConversationCardClick: (
    conversationId?: string,
    projectId?: string,
    shouldFetchConversations?: boolean
  ) => void;
};

const ChatCard = ({ onConversationCardClick, conversation, IsSearchFocused }: ChatCardProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { currentConversation, searchQuery } = useSelector(selectConversations);
  const currentConversationCardRef = createRef<HTMLDivElement>();

  const analytics = useAnalytics();

  const handleConversationDelete = (conversationId: string) => {
    dispatch(deleteConversation(conversationId)).then(() => {
      onConversationCardClick(undefined, conversation.projectId);
    });
  };

  const handleConversationDownload = (conversation: Conversation) => {
    dispatch(downloadFile(conversation.id)).then(
      (action: PayloadAction<DownloadConversationResponse | unknown>) => {
        const conversationId = conversation.id;
        analytics.event(AnalyticsEvent.WM_NIGEL_CHAT_DOWNLOAD, { id: conversationId });
        const response = action.payload as DownloadConversationResponse;
        filesaver.saveAs(
          new Blob([response.conversation]),
          `${
            conversation.title.length > 50
              ? conversation.title.slice(0, 50).concat('...')
              : conversation.title
          }.md`
        );
      }
    );
  };
  const handleReusePrompt = (promptId?: string) => {
    navigate(`/chat?prompt=${promptId}`);
  };

  const handleConversationShare = (conversationId: string, newValue: boolean) => {
    dispatch(updateConversation({ updatedFields: { shared: newValue }, conversationId }));

    const sharingMessage = newValue ? 'shared' : 'unshared';
    enqueueSnackbar(`Conversation ${sharingMessage}`, { variant: 'success' });
  };

  const isElementInView = (element: HTMLElement): boolean => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  useEffect(() => {
    const getScrollParent = (element: HTMLElement): HTMLElement | null => {
      if (!element) {
        return document.documentElement;
      }

      const isElementScrollable = element.scrollHeight > element.clientHeight;
      if (isElementScrollable) {
        return element;
      }

      return element.parentElement ? getScrollParent(element.parentElement) : null;
    };

    const element = currentConversationCardRef.current;
    if (element && !isElementInView(element) && !IsSearchFocused) {
      // We use a setTimeout here to push this DOM update to the next event loop,
      // ensuring it doesn't get overriden
      setTimeout(() => {
        if (!isElementInView(element)) {
          const elementRect = element.getBoundingClientRect();
          const scrollParent = getScrollParent(element);

          if (scrollParent) {
            const targetScrollTop =
              elementRect.top +
              scrollParent.scrollTop -
              scrollParent.clientHeight / 2 +
              elementRect.height / 2;

            scrollParent.scrollTo({
              top: targetScrollTop,
            });
          }
        }
      }, 100);
    }
  }, [currentConversation, currentConversationCardRef, IsSearchFocused]);

  const isCurrentlySelected = currentConversation?.id === conversation.id;

  return (
    <DrawerCard
      title={
        conversation.title.length > 50
          ? conversation.title.slice(0, 50).concat('...')
          : conversation.title
      }
      id={conversation.id}
      time={conversation.lastActivityAt}
      appName={
        conversation.appName !== AppEnum.DEAL_GPT
          ? AppNameBannerMap[conversation.appName]
          : (conversation.projectName as string)
      }
      selected={isCurrentlySelected}
      hasFileUploaded={conversation.fileUpload === true}
      shared={conversation.shared}
      canBeShared={conversation.appName !== AppEnum.DEAL_GPT}
      hasPrompt={!!conversation.promptId}
      type="conversation"
      onDrawerCardClick={() =>
        onConversationCardClick(conversation.id, conversation.projectId, false)
      }
      onDrawerCardUpdate={() => onConversationCardClick(conversation.id, conversation.projectId)}
      onDrawerCardRename={(newTitle: string) =>
        dispatch(
          updateConversation({
            updatedFields: { title: newTitle },
            conversationId: conversation.id,
          })
        ).then(() => {
          onConversationCardClick(conversation.id, conversation.projectId);
        })
      }
      onDrawerCardDelete={() => handleConversationDelete(conversation.id)}
      handleDrawerCardDownload={() => handleConversationDownload(conversation)}
      reusePrompt={() => handleReusePrompt(conversation.promptId)}
      onDrawerCardShareUpdate={(newValue) => handleConversationShare(conversation.id, newValue)}
      ref={isCurrentlySelected ? currentConversationCardRef : undefined}
      searchQuery={searchQuery}
      matchingMessage={conversation.matchingMessage}
    />
  );
};

export default ChatCard;
