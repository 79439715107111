import { styled } from '@mui/material';
import React, { useEffect, useState } from 'react';

import type { ProjectRunAnalysisQuestion } from '../../../../Types/dealGPT';
import { FeedbackInterface } from '../../../../Types/feedback';
import RubricSection from './RubricSection';

const Section = styled('div')({
  '&:not(:first-of-type)': {
    borderTop: '1px solid #C4C4C4',
    paddingTop: '20px',
  },
});

const getUniqueAreas = (questions: ProjectRunAnalysisQuestion[]): string[] => {
  // Step 1: Create a map to store each area with its minimum display order
  const areaDisplayOrderMap: { [area: string]: number } = {};

  questions.forEach((question) => {
    const area = question.area as string;
    const displayOrder = question.displayOrder ?? Number.MAX_SAFE_INTEGER;

    // Update the minimum display order for the area
    if (!(area in areaDisplayOrderMap) || displayOrder < areaDisplayOrderMap[area]) {
      areaDisplayOrderMap[area] = displayOrder;
    }
  });

  // Step 2: Sort areas based on their minimum display order
  const sortedAreas = Object.keys(areaDisplayOrderMap).sort(
    (areaA, areaB) => areaDisplayOrderMap[areaA] - areaDisplayOrderMap[areaB]
  );

  return sortedAreas;
};

type AreaMapType = {
  [area: string]: {
    [dimension: string]: ProjectRunAnalysisQuestion[];
  };
};

const groupByAreaAndDimension = (questions: ProjectRunAnalysisQuestion[]): AreaMapType => {
  const areaMap: AreaMapType = {};

  questions.forEach((question) => {
    const area = question.area ?? 'undefined';
    if (!areaMap[area]) {
      areaMap[area] = {};
    }

    const dimension = question.dimension ?? 'undefined';
    if (!areaMap[area][dimension]) {
      areaMap[area][dimension] = [];
    }

    areaMap[area][dimension].push(question);
  });

  // Sort each dimension array in descending order by createdAt
  Object.keys(areaMap).forEach((area) => {
    Object.keys(areaMap[area]).forEach((dimension) => {
      areaMap[area][dimension].sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    });
  });

  return areaMap;
};

const sortDimensionsByDisplayOrder = (area: {
  [dimension: string]: ProjectRunAnalysisQuestion[];
}): ProjectRunAnalysisQuestion[][] => {
  const dimensionsArray = Object.values(area);

  dimensionsArray.sort((a, b) => {
    const displayOrderA = a[0]?.displayOrder ?? Number.MAX_SAFE_INTEGER;
    const displayOrderB = b[0]?.displayOrder ?? Number.MAX_SAFE_INTEGER;
    return displayOrderA - displayOrderB;
  });

  return dimensionsArray;
};

// Sample Area Map
// {
//   'Scaleability': {
//     'Compute & Storage': [{ sequence: 1, displayOrder: 1, area: 'Scaleability', dimension: 'Compute & Storage', createdAt: '2023-06-26T14:48:00.000Z', response: 'response1' }, { sequence: 1, displayOrder: 1, area: 'Scaleability', dimension: 'Compute & Storage', createdAt: '2023-06-24T14:48:00.000Z', response: 'response2' }],
//     'Network (WAN & LAN)': [{ sequence: 1, displayOrder: 2, area: 'Scaleability', dimension: 'Network (WAN & LAN)', createdAt: '2023-06-27T14:48:00.000Z', response: 'response2' }],
//     'Database & Caching': [{ sequence: 1, displayOrder: 3, area: 'Scaleability', dimension: 'Database & Caching', createdAt: '2023-06-28T14:48:00.000Z', response: 'response3' }]
//   },
//   'Availability': {
//     'Hosting (Infra & On-Prem/Cloud)': [{ sequence: 1, displayOrder: 4, area: 'Availability', dimension: 'Hosting (Infra & On-Prem/Cloud)', createdAt: '2023-06-28T14:48:00.000Z', response: 'response4' }]
//   }
// };

interface RubricPageProps {
  pageQuestions?: ProjectRunAnalysisQuestion[];
  reprocessingTriggered: () => void;
  setIsLoadingThis: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoadingApplyChanges: React.Dispatch<React.SetStateAction<boolean>>;
  expandAll: boolean;
  handleFeedbackSubmit: (feedback: FeedbackInterface, projectAnalysisQuestionId: string) => void;
}

const RubricPage: React.FC<RubricPageProps> = ({
  pageQuestions,
  reprocessingTriggered,
  setIsLoadingThis,
  setIsLoadingApplyChanges,
  expandAll,
  handleFeedbackSubmit,
}) => {
  const [areas, setAreas] = useState<string[]>([]);
  const [areaMap, setAreaMap] = useState<AreaMapType>({});

  useEffect(() => {
    // console.log('initial page questions', initialPageQuestions);
    // console.log('page questions', pageQuestions);

    // Clear these out when switching pages
    setAreas([]);
    setAreaMap({});

    if (pageQuestions && pageQuestions.length > 0) {
      // console.log('all versions', pageQuestions);
      const areaMap = groupByAreaAndDimension(pageQuestions);
      // console.log('areaMap', areaMap);
      setAreaMap(areaMap);
      const uniqueAreas = getUniqueAreas(pageQuestions);
      if (uniqueAreas) {
        setAreas(uniqueAreas);
      }
    }
  }, [pageQuestions]);

  return (
    <div>
      <div style={{ display: 'flex', gap: '16px', width: '100%', justifyContent: 'end' }}></div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {areas.length > 0 &&
          areas.map((area) => (
            <Section key={area}>
              <RubricSection
                areaName={area}
                dimensions={sortDimensionsByDisplayOrder(areaMap[area])}
                reprocessingTriggered={reprocessingTriggered}
                setIsLoadingThis={setIsLoadingThis}
                setIsLoadingApplyChanges={setIsLoadingApplyChanges}
                expandAll={expandAll}
                handleFeedbackSubmit={handleFeedbackSubmit}
              />
            </Section>
          ))}
      </div>
    </div>
  );
};

export default RubricPage;
