// import StarIcon from '@mui/icons-material/Star';
// import { Button, List, ListItem, styled } from '@mui/material';
import { Button, styled } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';

import { selectAppStatus, setCurrentApp } from '../../redux/reducers/appStatusReducer';
import { AppDispatch } from '../../redux/store';
import { setFocusById } from '../../services/util';
import { AppEnum, AppNameBannerMap } from '../../Types/enums';
import { useFeatures } from '../Providers/FeatureProvider';
import { useProjects } from '../Providers/ProjectsProvider';

const DrawerAppsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  borderBottom: '1px solid #C4C4C4',
  paddingBottom: '16px',
});

type AppButtonProps = {
  selected: boolean;
};

const AppButton = styled(Button)<AppButtonProps>(({ selected }) => ({
  color: '#070154',
  justifyContent: 'flex-start',
  backgroundColor: selected ? '#EFF5F6' : 'transparent',
  fontWeight: selected ? '700' : '500',
}));

// const ProjectsList = styled(List)({
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'start',
//   width: '100%',
//   justifyContent: 'flex-start',
//   margin: '0 0 0 16px',
// });

// type ButtonProps = {
//   selected: boolean;
// };

// const ProjectButton = styled(AppButton)<ButtonProps>({
//   width: '100%',
// });

const DrawerApps = (): JSX.Element => {
  const navigate = useNavigate();
  const { selectedApp } = useSelector(selectAppStatus);
  const dispatch = useDispatch<AppDispatch>();
  const features = useFeatures();
  // const { projects, hasProjects, setShouldFetch } = useProjects();
  const { setShouldFetch } = useProjects();
  // const { projectId } = useParams();
  const location = useLocation();

  const handleAppSelection = (appName: AppEnum = AppEnum.NIGEL, projectId?: string) => {
    dispatch(setCurrentApp(appName));

    if (appName === AppEnum.DEAL_GPT) {
      if (projectId) {
        navigate(`/intellio-advantage/${projectId}`);
      } else {
        navigate('/intellio-advantage');
      }
    } else {
      navigate('/chat');
      setFocusById('chat-box');
    }
  };

  useEffect(() => {
    if (location.pathname.includes('deal-gpt') && selectedApp !== AppEnum.DEAL_GPT) {
      dispatch(setCurrentApp(AppEnum.DEAL_GPT));
    }
  }, [selectedApp, location.pathname, dispatch]);

  return (
    <DrawerAppsContainer>
      <AppButton
        variant="text"
        selected={selectedApp === AppEnum.NIGEL}
        onClick={() => handleAppSelection()}
      >
        {AppNameBannerMap[AppEnum.NIGEL]}
      </AppButton>
      {features.app_employee_handbook_access && (
        <AppButton
          variant="text"
          selected={selectedApp === AppEnum.WM_EMPLOYEE_HANDBOOK}
          onClick={() => handleAppSelection(AppEnum.WM_EMPLOYEE_HANDBOOK)}
        >
          {AppNameBannerMap[AppEnum.WM_EMPLOYEE_HANDBOOK]}
        </AppButton>
      )}
      {features.app_energy_utilities_demo_access && (
        <AppButton
          variant="text"
          selected={selectedApp === AppEnum.ENERGY_UTILITIES_DEMO}
          onClick={() => handleAppSelection(AppEnum.ENERGY_UTILITIES_DEMO)}
        >
          {AppNameBannerMap[AppEnum.ENERGY_UTILITIES_DEMO]}
        </AppButton>
      )}
      {features.app_bia_access && (
        <AppButton
          variant="text"
          selected={selectedApp === AppEnum.BIA}
          onClick={() => handleAppSelection(AppEnum.BIA)}
        >
          {AppNameBannerMap[AppEnum.BIA]}
        </AppButton>
      )}
      {features.app_dalle_access && (
        <AppButton
          variant="text"
          selected={selectedApp === AppEnum.DALLE}
          onClick={() => handleAppSelection(AppEnum.DALLE)}
        >
          {AppNameBannerMap[AppEnum.DALLE]}
        </AppButton>
      )}
      <AppButton
        variant="text"
        // selected={selectedApp === AppEnum.DEAL_GPT && !projectId}
        selected={selectedApp === AppEnum.DEAL_GPT}
        onClick={() => {
          handleAppSelection(AppEnum.DEAL_GPT);
          setShouldFetch(true);
        }}
      >
        {AppNameBannerMap[AppEnum.DEAL_GPT]}
      </AppButton>
      {/* Will be implemented with favoriting/stars or most recent projects */}
      {/* {appState.selectedApp === AppEnum.DEAL_GPT && hasProjects && (
        <ProjectsList>
          {projects &&
            projects.map((project) => (
              <ListItem key={project.id} disablePadding>
                <ProjectButton
                  selected={projectId === project.id}
                  onClick={() => handleAppSelection(AppEnum.DEAL_GPT, project.id)}
                >
                  <StarIcon sx={{ width: '16px', marginRight: '8px' }} />
                  {project.clientName ? `${project.clientName} - ` : ''}
                  {project.name}
                </ProjectButton>
              </ListItem>
            ))}
        </ProjectsList>
      )} */}
    </DrawerAppsContainer>
  );
};

export default DrawerApps;
