import { OpenInNew } from '@mui/icons-material';
import { List, ListItem, styled, Typography } from '@mui/material';
import React from 'react';

const StyledListItem = styled(ListItem)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  paddingTop: '0',
  paddingBottom: '10px',
});

const StyledLink = styled('a')({
  color: 'inherit',
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'underline',
  },
});

const OpenInNewIcon = (
  <OpenInNew sx={{ fontSize: '20px', marginRight: '2px', verticalAlign: 'text-top' }} />
);

const FeatureHighlights: React.FC = () => {
  return (
    <>
      <Typography
        variant="subtitle2"
        sx={{
          height: '20px',
          color: '#0047FF',
          margin: 0,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        NEW FEATURE HIGHLIGHTS
      </Typography>
      <div style={{ height: 'inherit' }}>
        <List sx={{ height: 'inherit', marginTop: '10px', paddingTop: '0', paddingBottom: '0' }}>
          <StyledListItem>
            <Typography variant="body2">
              <b>🎨 Redesigned Prompts Page</b>
            </Typography>
            <Typography variant="body2">
              {`We made it easier to find and use relevant prompts in a single location. You can use  “All Prompts” to view private and all published prompts. Or you can try “Recently Used” to view prompts used in the last 30 days.`}
            </Typography>
          </StyledListItem>
          <StyledListItem>
            <Typography variant="body2">
              <b>🤖 ExpertGPTs</b>
            </Typography>
            <Typography variant="body2">
              Click{' '}
              <StyledLink
                href="https://experts.westmonroe.com/assistants"
                target="_blank"
                style={{
                  lineHeight: '20px',
                  fontSize: '14px',
                }}
              >
                {OpenInNewIcon}
                <b>HERE</b>
              </StyledLink>{' '}
              to access all available assistants to help with <b>How we Sell</b> and{' '}
              <b>How we Deliver</b>.
            </Typography>
          </StyledListItem>
        </List>
      </div>
    </>
  );
};

export default FeatureHighlights;
