// Apps
export enum AppEnum {
  NIGEL = 'NIGEL',
  BIA = 'BIA',
  DALLE = 'DALL-E',
  DEAL_GPT = 'DEAL_GPT',
  ENERGY_UTILITIES_DEMO = 'ENERGY_AND_UTILITIES_DEMO',
  WM_EMPLOYEE_HANDBOOK = 'WM_EMPLOYEE_HANDBOOK',
}

export enum Llm {
  GPT_4_32K = 'wm-prompt-lib-gpt-4-32k',
  GPT_35_TURBO_16K = 'wm-prompt-lib-gpt-35-turbo-16k',
  GPT_4 = 'wm-prompt-lib-gpt-4',
  GPT_4_TURBO_128K = 'wm-prompt-lib-gpt-4-turbo-128k',
  GPT_4O_128K = 'wm-prompt-lib-gpt-4o-128k-global',
  GLEAN = 'glean',
}

export const LlmMapping: { [key in Llm]: string } = {
  [Llm.GPT_4_32K]: 'GPT 4',
  [Llm.GPT_35_TURBO_16K]: 'GPT 3.5',
  [Llm.GPT_4]: 'GPT 4 (non 32k)',
  [Llm.GPT_4_TURBO_128K]: 'GPT 4 Turbo',
  [Llm.GPT_4O_128K]: 'GPT 4o',
  [Llm.GLEAN]: 'Glean',
};

export const AppNameBannerMap = {
  [AppEnum.NIGEL]: 'Nigel',
  [AppEnum.BIA]: 'BIA',
  [AppEnum.DALLE]: 'Dall-E',
  [AppEnum.DEAL_GPT]: 'Intellio® Advantage',
  [AppEnum.ENERGY_UTILITIES_DEMO]: 'OGC Assist',
  [AppEnum.WM_EMPLOYEE_HANDBOOK]: 'Employee Handbook',
};

// Conversations
export enum MessageFrom {
  AI = 'AI',
  USER = 'USER',
}

// Users
export enum UserRole {
  ADMIN = 'ADMIN',
  DEALGPT_ADMIN = 'DEALGPT_ADMIN',
}

// Files
export enum FileUploadMimeType {
  PLAIN_TEXT = 'text/plain',
  MARKDOWN = 'text/markdown',
  MS_WORD_DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

export enum ToolName {
  SEARCH_SHAREPOINT_FILES = 'searchSharepointFiles',
  SEARCH_SHAREPOINT_SITES = 'searchSharepointSites',
  SEARCH_SHAREPOINT_FOLDERS = 'searchSharepointFolders',
  SEARCH_WEST_MONROE = 'searchWestMonroe',
  SEARCH_INTELLIO_DATA = 'searchIntellioInsightsInfrastructureRubricData',
  SEARCH_INTELLIO_MCS_MODEL_LINE_DETAILS = 'searchIntellioInsightsMcsModelLineDetails',
  SEARCH_INTELLIO_MCS_SUMMARY_DATA = 'searchIntellioInsightsMcsSummaryData',
  SEARCH_INTELLIO_KPI_LIST = 'searchIntellioInsightsKpiList',
  SEARCH_INTELLIO_MA_GENAI_DILIGENCE_SSA_DETAIL = 'searchIntellioInsightsMaGenaiDiligenceSsaDetail',
  SEARCH_INTELLIO_MA_GENAI_DILIGENCE_SSA_AGGREGATE = 'searchIntellioInsightsMaGenaiDiligenceSsaAggregate',
  SEARCH_INTELLIO_SCHEMAS = 'searchIntellioSchemas',
  SEARCH_INTELLIO_QUERY = 'searchIntellioQuery',
}

export const ToolNameMap = {
  [ToolName.SEARCH_SHAREPOINT_FILES]: 'Search Sharepoint Files',
  [ToolName.SEARCH_SHAREPOINT_SITES]: 'Search Sharepoint Sites',
  [ToolName.SEARCH_SHAREPOINT_FOLDERS]: 'Search Sharepoint Folders',
  [ToolName.SEARCH_WEST_MONROE]: 'Search West Monroe',
  [ToolName.SEARCH_INTELLIO_DATA]: 'Search Intellio Insights Infrastructure Rubric Data',
  [ToolName.SEARCH_INTELLIO_MCS_MODEL_LINE_DETAILS]:
    'Search Intellio Insights MCS Model Line Details',
  [ToolName.SEARCH_INTELLIO_MCS_SUMMARY_DATA]: 'Search Intellio Insights MCS Summary Data',
  [ToolName.SEARCH_INTELLIO_KPI_LIST]: 'Search Intellio Insights KPI List',
  [ToolName.SEARCH_INTELLIO_MA_GENAI_DILIGENCE_SSA_DETAIL]:
    'Search Intellio Insights M&A Genai Diligence SSA Detail',
  [ToolName.SEARCH_INTELLIO_MA_GENAI_DILIGENCE_SSA_AGGREGATE]:
    'Search Intellio Insights M&A Genai Diligence SSA Aggregate',
  [ToolName.SEARCH_INTELLIO_SCHEMAS]: 'Search Intellio Schemas',
  [ToolName.SEARCH_INTELLIO_QUERY]: 'Search Intellio Query',
};

// Prompts
export enum Tag {
  SUMMARIZE = 'Summarize',
  NOTES = 'Notes',
  EVALUATION = 'Evaluation',
  EMAIL = 'Email',
  GENERAL = 'General',
  PRODUCTIVITY = 'Productivity',
  BRAINSTORMING = 'Brainstorming',
  RESEARCH = 'Research',
  CONSUMER = 'Consumer & Industrial Products',
  ENERGY = 'Energy & Utilities',
  FINANCIAL = 'Financial Services',
  HEALTHCARE = 'Healthcare & Life Sciences',
  HIGH_TECH = 'High Tech & Software',
  CUSTOMER_SOLUTIONS = 'Customer Solutions',
  HUMAN_CAPITAL = 'Human Capital Management',
  OPERATIONS_EXCELLENCE = 'Operations Excellence',
  TECHNOLOGY = 'Technology',
  PRODUCT_EXPERIENCE = 'Product Experience & Engineering Lab',
  MERGERS_AND_AQUISITIONS = 'Mergers & Aquisitions',
  INTELLIO_INSIGHTS = 'Intellio Insights',
}

export enum PromptSortOptions {
  LIKES = 'LIKES',
  USAGE = 'USAGE',
  DEFAULT = 'DEFAULT',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

// Admin
export enum AdminTab {
  USER_STATS = 'USER_STATS',
  FILE_MANAGEMENT = 'FILE_MANAGEMENT',
}

export enum PromptView {
  ALL_PROMPTS = 'ALL_PROMPTS',
  PUBLISHED = 'PUBLISHED',
  RECENT = 'RECENT',
  PINNED = 'PINNED',
  MY_PROMPTS = 'MY_PROMPTS',
}

export enum DealGPTView {
  DISCOVERY = 'DISCOVERY',
  ANALYSIS = 'ANALYSIS',
  CHAT = 'CHAT',
  SCOPE_AREAS = 'SCOPE_AREAS',
}

export enum DealGPTQuestionStatus {
  PENDING = 'PENDING',
  ANSWERED = 'ANSWERED',
  MORE_INFO_NEEDED = 'MORE_INFO_NEEDED',
  NOT_FOUND = 'NOT_FOUND',
  ERROR = 'ERROR',
  SKIPPED = 'SKIPPED',
}

export enum DealGPTTone {
  POSITIVE = 'Positive',
  NEGATIVE = 'Negative',
  LESS_TECHNICAL = 'Less Technical',
  MORE_TECHNICAL = 'More Technical',
  MORE_CONCISE = 'More Concise',
}

export enum UpdatedReason {
  USER_EDIT = 'USER_EDIT',
  TONE_CHANGE = 'TONE_CHANGE',
  REPROCESS = 'REPROCESS',
  INITIAL = 'INITIAL',
  USER_INPUT = 'USER_INPUT',
}

export enum ProcessReason {
  PROCESS_THIS = 'PROCESS_THIS',
  APPLY_CHANGES = 'APPLY_CHANGES',
  PROCESS_THIS_AND_APPLY_CHANGES = 'PROCESS_THIS_AND_APPLY_CHANGES',
}

export enum AnalysisQuestionType {
  PROMPT_ANSWER = 'PROMPT_ANSWER',
  QUESTION_AND_ANSWER = 'QUESTION_AND_ANSWER',
  KPI = 'KPI',
  RUBRIC = 'RUBRIC',
  NOTES = 'NOTES',
}

export enum ProjectStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  ARCHIVED = 'ARCHIVED',
}

export enum RunType {
  DISCOVERY = 'DISCOVERY',
  ANALYSIS = 'ANALYSIS',
}

export enum ScopeAreasStatus {
  NOT_STARTED = 'NOT STARTED',
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN PROGRESS',
  COMPLETE = 'COMPLETE',
}

export enum FeedbackOptions {
  NOT_ENOUGH_INFORMATION = 'Not Enough Information',
  TOO_VAGUE = 'Too Vague',
  UNHELPFUL = 'Unhelpful',
  BEING_LAZY = 'Being Lazy',
  INACCURATE_FALSE_INFORMATION = 'Inaccurate/False Information',
  SLOW_RESPONSE_TIME = 'Slow Response Time',
  WRONG_TONE = 'Wrong Tone',
  INCORRECT_CONTEXT = 'Incorrect Context',
  INCORRECT_FALSE_INFORMATION = 'Incorrect/False Information',
  INCOMPLETE_ANSWER = 'Incomplete Answer',
  IRRELEVANT_ANSWER = 'Irrelevant Answer',
  OUTDATED_INFORMATION = 'Outdated Information',
  UNCLEAR_CONFUSING = 'Unclear/Confusing',
  TECHNICAL_ISSUES = 'Technical Issues',
}
