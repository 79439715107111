import {
  Autocomplete,
  Button,
  Container,
  createFilterOptions,
  List,
  ListItem,
  MenuItem,
  styled,
  TextField as MUITextField,
  Typography,
  TypographyProps,
} from '@mui/material';
import { TextField } from 'mui-rff';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';

import ModificationConfirmationDialog from '../../components/Prompts/ModificationConfirmationDialog';
import { useAuth } from '../../components/Providers/AuthProvider';
import { API_URL, enrichedFetch } from '../../services/api';
import { AccountType, InterviewType } from '../../Types/AIAccountCoach/accountCoach';

const MainDiv = styled('div')({
  display: 'flex',
  height: '100vh',
  overflowY: 'auto',
  div: {
    position: 'relative',
  },
});

const InterviewContainer = styled('div')(() => ({
  width: '100%',
  position: 'relative',
  zIndex: 1,
  background: 'white',
  height: '100%',
  overflowY: 'auto',
}));

const StyledForm = styled('form')({
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  margin: '0 20px 20px 20px',
});

const StyledSubmitButton = styled(Button)(() => {
  return {
    width: '25%',
    margin: '16px',
  };
});

const StyledTextField = styled(TextField)({
  '&:hover': {
    outline: '1px solid #93D0D6',
    borderRadius: '4px',
  },
  '&:active': {
    outline: '1px solid #7EB5C0',
    borderRadius: '4px',
  },
});

const StyledLabel = styled((props: TypographyProps) => (
  <Typography variant="subtitle2" {...props} />
))({
  fontWeight: 'normal',
  marginBottom: '10px',
});

const StyledCaption = styled((props: TypographyProps) => (
  <Typography variant="caption1" {...props} />
))({
  fontStyle: 'italic',
  fontWeight: 'normal',
  marginBottom: '10px',
});

const INTERVIEW_DEFAULTS: InterviewType = {
  account_name: '',
  account_id: '',
  account_director: '',
  account_manager: '',
  account_industry: '',
  account_sub_industry: '',
  interviewee_email: '',
  respondent_role: '',
  int_decision_makers: '',
  int_feedback: '',
  int_investments: '',
  int_painpoints: '',
  int_changes: '',
  int_delivery_challenges: '',
};

const Interview = () => {
  const { currentUser } = useAuth();

  const [isModificationConfirmationOpen, setIsModificationConfirmationOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentInterview, setCurrentInterview] = useState<InterviewType>(INTERVIEW_DEFAULTS);
  const [formKey, setFormKey] = useState(0);
  const [accounts, setAccounts] = useState<AccountType[]>([]);

  useEffect(() => {
    const loadAccounts = async () => {
      const module = await import('./accounts_10_14_24');
      setAccounts(module.default);
    };

    loadAccounts();
  }, []);

  const handleConfirm = async () => {
    setIsSubmitting(true);
    try {
      await enrichedFetch(`${API_URL}/account-coach/interview`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(currentInterview),
      });

      enqueueSnackbar('Interview submitted!', { variant: 'success' });

      setTimeout(() => {
        window.location.href =
          'https://westmonroepartners1.sharepoint.com/sites/WM-ExpertGPTs/SitePages/AI-Account-Coach.aspx?csf=1&web=1&e=8CVHYo&ovuser=c2a23be5-d2cf-4e70-84ce-18adf87d571f%2Cjklobucar%40westmonroepartners.com&OR=Teams-HL&CT=1726629090365&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI0OS8yNDA4MTcwMDQxOSIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D&CID=9e5451a1-20b0-6000-936f-42ae1d50a8ca&cidOR=SPO';
      }, 2000);

      setCurrentInterview(INTERVIEW_DEFAULTS);

      setFormKey(formKey + 1);
    } catch (error) {
      enqueueSnackbar('Retool Error', { variant: 'error' });
    }
    setIsSubmitting(false);
  };

  const onSubmit = (values: InterviewType) => {
    const newInterview = {
      account_name: values['account_name'],
      account_id: values['account_id'],
      account_director: values['account_director'],
      account_manager: values['account_manager'],
      account_industry: values['account_industry'],
      account_sub_industry: values['account_sub_industry'],
      interviewee_email: currentUser?.email || '',
      respondent_role: values['respondent_role'],
      int_decision_makers: values['int_decision_makers'],
      int_feedback: values['int_feedback'],
      int_investments: values['int_investments'],
      int_painpoints: values['int_painpoints'],
      int_changes: values['int_changes'],
      int_delivery_challenges: values['int_delivery_challenges'],
    } as InterviewType;

    setCurrentInterview(newInterview);

    setIsModificationConfirmationOpen(true);
  };

  return (
    <MainDiv>
      <ModificationConfirmationDialog
        onConfirm={() => handleConfirm()}
        onCancel={() => {
          setIsModificationConfirmationOpen(false);
        }}
        isOpen={isModificationConfirmationOpen}
        setIsOpen={setIsModificationConfirmationOpen}
        title={'Are you sure you want to submit this interview?'}
      />

      <div style={{ overflow: 'auto', width: '100%', minHeight: 'calc(100% - 64px)' }}>
        <InterviewContainer>
          <FinalForm
            key={formKey}
            initialValues={{ ...INTERVIEW_DEFAULTS }}
            onSubmit={onSubmit}
            render={({ handleSubmit, form, values }) => (
              <StyledForm onSubmit={handleSubmit}>
                <Typography
                  variant="h1"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '30px',
                    marginTop: '50px',
                  }}
                >
                  AI Account Coach Interview
                </Typography>
                <Container sx={{ display: 'flex' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '40vw' }}>
                    <StyledLabel>
                      What is the name of the account that you will be providing information on
                      behalf of?
                    </StyledLabel>

                    <Autocomplete
                      options={accounts}
                      disableClearable
                      getOptionLabel={(account) => (account as AccountType).account_name}
                      filterOptions={createFilterOptions({
                        matchFrom: 'any',
                        limit: 500,
                      })}
                      renderInput={(params) => (
                        <MUITextField {...params} required variant="outlined" />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.account_id === value.account_id
                      }
                      value={{
                        account_name: values.account_name,
                        account_id: values.account_id,
                        account_director: values.account_director,
                        account_manager: values.account_manager,
                        account_industry: values.account_industry,
                        account_sub_industry: values.account_sub_industry,
                      }}
                      onChange={(_, newValue) => {
                        form.change('account_name', newValue?.account_name);
                        form.change('account_id', newValue?.account_id);
                        form.change('account_director', newValue?.account_director);
                        form.change('account_manager', newValue?.account_manager);
                        form.change('account_industry', newValue?.account_industry);
                        form.change('account_sub_industry', newValue?.account_sub_industry);
                      }}
                      sx={{
                        '& .MuiAutocomplete-inputRoot': {
                          paddingRight: '0 !important',
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                          right: '10px',
                          transform: 'translateY(50%) translateX(40%)',
                        },
                      }}
                    />
                    <br />

                    <StyledLabel>What is your role on the account team?</StyledLabel>
                    <TextField select required name="respondent_role">
                      <MenuItem value="Account Director">Account Director</MenuItem>
                      <MenuItem value="Account Manager">Account Manager</MenuItem>
                      <MenuItem value="Business Developer">Business Developer</MenuItem>
                      <MenuItem value="Engagement Lead">Engagement Lead</MenuItem>
                      <MenuItem value="Key Delivery Team Member">Key Delivery Team Member</MenuItem>
                      <MenuItem value="Senior Client Partner">Senior Client Partner</MenuItem>
                    </TextField>
                    <br />

                    <Typography variant="body1">
                      {`Let's get started`} <br />
                      <br />
                      As a key member of our account teams, your insights are invaluable to
                      understanding our {`clients'`} needs and relationships. <br />
                      <br />
                      To gain a well-rounded perspective, we ask that{' '}
                      <b>the following individuals complete an interview</b> for your account:
                    </Typography>
                    <List sx={{ listStyleType: 'disc', pl: '50px' }}>
                      <ListItem sx={{ display: 'list-item' }}>
                        <Typography>Account Director</Typography>
                      </ListItem>
                      <ListItem sx={{ display: 'list-item' }}>
                        <Typography>Account Manager</Typography>
                      </ListItem>
                      <ListItem sx={{ display: 'list-item' }}>
                        <Typography>Business Developer(s)</Typography>
                      </ListItem>
                      <ListItem sx={{ display: 'list-item' }}>
                        <Typography>Engagement Lead(s)</Typography>
                      </ListItem>
                      <ListItem sx={{ display: 'list-item' }}>
                        <Typography>Key Delivery Team Members</Typography>
                      </ListItem>
                      <ListItem sx={{ display: 'list-item' }}>
                        <Typography>Senior Client Partner</Typography>
                      </ListItem>
                    </List>
                    <Typography variant="body1">
                      Involving these team members allows the AI Account Coach to gather diverse
                      perspectives and provide a more comprehensive output.
                      <br />
                      <br />
                    </Typography>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '40px' }}>
                    <StyledLabel style={{ fontWeight: 'bold' }}>
                      Please provide the following information based on your experiences with the
                      account:
                    </StyledLabel>
                    <br />
                    <StyledLabel>
                      1. Who are the key decision-makers and influencers you’ve interacted with, and
                      what personal motivations or internal pressures drive their decisions? Please
                      indicate if they are a blocker, neutral, supporter, or champion of West
                      Monroe.
                    </StyledLabel>
                    <StyledCaption>
                      If Private Equity, please clarify the information that is Portfolio Company
                      specific.
                    </StyledCaption>
                    <StyledTextField
                      name="int_decision_makers"
                      variant="outlined"
                      required
                      fullWidth
                      multiline
                      minRows={3}
                    />
                    <br />

                    <StyledLabel>
                      2. How have the {`client's`} decision-makers described West Monroe in private
                      conversations? Are there any anecdotes or informal feedback on what they value
                      or dislike about our partnership?
                    </StyledLabel>
                    <StyledCaption>
                      If Private Equity, please clarify the information that is Portfolio Company
                      specific.
                    </StyledCaption>
                    <TextField
                      name="int_feedback"
                      variant="outlined"
                      required
                      fullWidth
                      multiline
                      minRows={3}
                    />
                    <br />

                    <StyledLabel>
                      3. Based on your conversations, what recent or upcoming investments is the
                      client prioritizing that might not be public knowledge, and what internal
                      justifications have they shared?
                    </StyledLabel>
                    <StyledCaption>
                      If Private Equity, please clarify the information that is Portfolio Company
                      specific.
                    </StyledCaption>
                    <TextField
                      name="int_investments"
                      variant="outlined"
                      required
                      fullWidth
                      multiline
                      minRows={3}
                    />
                    <br />

                    <StyledLabel>
                      4. What unspoken pain points or private concerns have client stakeholders
                      shared that may not be formally part of their strategic plans?
                    </StyledLabel>
                    <StyledCaption>
                      If Private Equity, please clarify the information that is Portfolio Company
                      specific.
                    </StyledCaption>
                    <TextField
                      name="int_painpoints"
                      variant="outlined"
                      required
                      fullWidth
                      multiline
                      minRows={3}
                    />
                    <br />

                    <StyledLabel>
                      5. Have you heard of any unpublicized organizational changes (e.g., leadership
                      shifts or restructuring) that could impact their strategy or decision-making?
                    </StyledLabel>
                    <StyledCaption>
                      If Private Equity, please clarify the information that is Portfolio Company
                      specific.
                    </StyledCaption>
                    <TextField
                      name="int_changes"
                      variant="outlined"
                      required
                      fullWidth
                      multiline
                      minRows={3}
                    />
                    <br />

                    <StyledLabel>
                      6. How did the client’s team personally react to previous projects West Monroe
                      led, and were there any behind-the-scenes challenges that impacted those
                      outcomes?
                    </StyledLabel>
                    <StyledCaption>
                      If Private Equity, please clarify the information that is Portfolio Company
                      specific.
                    </StyledCaption>
                    <TextField
                      name="int_delivery_challenges"
                      variant="outlined"
                      required
                      fullWidth
                      multiline
                      minRows={3}
                    />
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <StyledSubmitButton type="submit" variant="contained" disabled={isSubmitting}>
                        Submit
                      </StyledSubmitButton>
                    </div>
                  </div>
                </Container>
              </StyledForm>
            )}
          />
        </InterviewContainer>
      </div>
    </MainDiv>
  );
};

export default Interview;
