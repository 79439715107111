import { createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';

import { API_URL, enrichedFetch } from '../../services/api';
import { PromptSortOptions } from '../../Types/enums';
import { CreatePrompt, Prompt, PromptAPIResponse, UpdatePrompt } from '../../Types/prompt';
import { TagCount } from '../../Types/tags';
import { stringArrayToURLQueryParam } from '../../util/stringUtils';
import { INITIAL_TAG_DEFAULTS } from '../consts';
import { setCurrentPrompt, setTagOptions } from '../reducers/promptReducer';
import { AppDispatch, RootState } from '../store';

export const findPromptTags = createAsyncThunk<TagCount[], void, { state: RootState }>(
  'prompts/findPromptTags',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const { publishedOnly, recentOnly, pinned, selectedTags, searchQuery } = getState().prompts;
    let url = `${API_URL}/prompts/tags?recentOnly=${recentOnly}`;

    if (publishedOnly !== undefined) {
      url += `&publishedOnly=${publishedOnly}`;
    }

    if (pinned !== undefined) {
      url += `&pinned=${pinned}`;
    }

    if (selectedTags.length) {
      const tagsQuery = stringArrayToURLQueryParam('tags', selectedTags);
      url += `&${tagsQuery}`;
    }

    if (searchQuery) {
      url += `&q=${searchQuery}`;
    }

    try {
      const response = (await enrichedFetch(url)) as TagCount[];

      const newTagOptions = { ...INITIAL_TAG_DEFAULTS };

      response.forEach(({ tag, count }) => {
        newTagOptions[tag] = +count;
      });

      dispatch(setTagOptions(newTagOptions));

      return response;
    } catch {
      enqueueSnackbar('Failed to fetch tags', { variant: 'error' });
      return rejectWithValue('Failed to fetch tags');
    }
  }
);

export const findPrompts = createAsyncThunk<PromptAPIResponse, void, { state: RootState }>(
  'prompts/findPrompts',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const {
      publishedOnly,
      sortOption,
      pageOffset,
      searchQuery,
      sortDirection,
      selectedTags,
      featuredOnly,
      liked,
      recentOnly,
      pinned,
    } = getState().prompts;
    let url = `${API_URL}/prompts?recentOnly=${recentOnly}&sort=${sortOption}&offset=${pageOffset}`;

    if (publishedOnly !== undefined) {
      url += `&publishedOnly=${publishedOnly}`;
    }
    if (featuredOnly) {
      url += `&featuredOnly=${featuredOnly}`;
    }
    if (pinned !== undefined) {
      url += `&pinned=${pinned}`;
    }
    if (liked !== undefined) {
      url += `&liked=${liked}`;
    }

    if (searchQuery) {
      url += `&q=${searchQuery}`;
    }

    if (sortOption !== PromptSortOptions.DEFAULT) {
      url += `&sortDirection=${sortDirection}`;
    }

    if (selectedTags.length) {
      const tagsQuery = stringArrayToURLQueryParam('tags', selectedTags);
      url += `&${tagsQuery}`;
    }

    try {
      const response = (await enrichedFetch(url)) as PromptAPIResponse;
      dispatch(findPromptTags());
      return response;
    } catch {
      enqueueSnackbar('Failed to fetch prompts', { variant: 'error' });
      return rejectWithValue('Failed to fetch prompts');
    }
  }
);

export const findPrompt = createAsyncThunk<Prompt, Prompt['id'], { state: RootState }>(
  'prompts/findPrompt',
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const response = (await enrichedFetch(`${API_URL}/prompts/prompt/${id}`)) as Prompt;

      dispatch(setCurrentPrompt(response));

      return response;
    } catch {
      enqueueSnackbar('Failed to fetch prompt', { variant: 'error' });
      return rejectWithValue('Failed to fetch prompt');
    }
  }
);

export const updatePrompt = createAsyncThunk<Prompt, { prompt: UpdatePrompt; promptId: string }>(
  'prompts/updatePrompt',
  async ({ prompt, promptId }, { rejectWithValue }) => {
    try {
      const response = (await enrichedFetch(`${API_URL}/prompts/${promptId}`, {
        method: 'PUT',
        body: JSON.stringify(prompt),
        headers: {
          'Content-Type': 'application/json',
        },
      })) as Prompt;
      return response;
    } catch {
      enqueueSnackbar('Failed to update prompt', { variant: 'error' });
      return rejectWithValue('Failed to update prompt');
    }
  }
);

export const updatePromptAdmin = createAsyncThunk<
  Prompt,
  { prompt: UpdatePrompt; promptId: string }
>('prompts/updatePromptAdmin', async ({ prompt, promptId }, { rejectWithValue }) => {
  try {
    const response = (await enrichedFetch(`${API_URL}/admin/prompts/${promptId}`, {
      method: 'PUT',
      body: JSON.stringify(prompt),
      headers: {
        'Content-Type': 'application/json',
      },
    })) as Prompt;
    return response;
  } catch {
    enqueueSnackbar('Failed to update prompt', { variant: 'error' });
    return rejectWithValue('Failed to update prompt');
  }
});

export const createPrompt = createAsyncThunk<Prompt, CreatePrompt>(
  'prompts/createPrompt',
  async (prompt, { dispatch, rejectWithValue }) => {
    try {
      const response = (await enrichedFetch(`${API_URL}/prompts`, {
        method: 'POST',
        body: JSON.stringify(prompt),
        headers: {
          'Content-Type': 'application/json',
        },
      })) as Prompt;

      (dispatch as AppDispatch)(findPrompts());

      return response;
    } catch {
      enqueueSnackbar('Failed to post prompt', { variant: 'error' });
      return rejectWithValue('Failed to post prompt');
    }
  }
);

export const createPromptAdmin = createAsyncThunk<Prompt, CreatePrompt>(
  'prompts/createPromptAdmin',
  async (prompt, { dispatch, rejectWithValue }) => {
    try {
      const response = (await enrichedFetch(`${API_URL}/admin/prompts`, {
        method: 'POST',
        body: JSON.stringify(prompt),
        headers: {
          'Content-Type': 'application/json',
        },
      })) as Prompt;

      (dispatch as AppDispatch)(findPrompts());

      return response;
    } catch {
      enqueueSnackbar('Failed to post prompt', { variant: 'error' });
      return rejectWithValue('Failed to post prompt');
    }
  }
);

export const deletePrompt = createAsyncThunk<void, Prompt['id']>(
  'prompts/deletePrompt',
  async (promptId, { dispatch, rejectWithValue }) => {
    try {
      await enrichedFetch(`${API_URL}/prompts/${promptId}`, {
        method: 'DELETE',
      });

      (dispatch as AppDispatch)(findPrompts());
    } catch {
      enqueueSnackbar('Failed to delete prompt', { variant: 'error' });
      return rejectWithValue('Failed to delete prompt');
    }
  }
);

export const deletePromptAdmin = createAsyncThunk<void, Prompt['id']>(
  'prompts/deletePromptAdmin',
  async (promptId, { dispatch, rejectWithValue }) => {
    try {
      await enrichedFetch(`${API_URL}/admin/prompts/${promptId}`, {
        method: 'DELETE',
      });

      (dispatch as AppDispatch)(findPrompts());
    } catch {
      enqueueSnackbar('Failed to delete prompt', { variant: 'error' });
      return rejectWithValue('Failed to delete prompt');
    }
  }
);

export const findTopPromptsLast30Days = createAsyncThunk<
  Prompt[],
  { numPrompts: number; publishedOnly: boolean },
  { state: RootState }
>(
  'prompts/findTopPromptsLast30Days',
  async ({ numPrompts, publishedOnly }, { rejectWithValue }) => {
    const url = `${API_URL}/prompts/topLast30Days?publishedOnly=${publishedOnly}&numPrompts=${numPrompts}`;

    try {
      const response = (await enrichedFetch(url)) as Prompt[];
      return response;
    } catch {
      enqueueSnackbar('Failed to fetch top 3 prompts from the last 30 days', { variant: 'error' });
      return rejectWithValue('Failed to fetch top 3 prompts');
    }
  }
);
