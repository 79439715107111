import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PromptSortOptions, PromptView, SortDirection, Tag } from '../../Types/enums';
import { Prompt, PromptAPIResponse } from '../../Types/prompt';
import { TagCounts } from '../../Types/tags';
import { findPrompts, findTopPromptsLast30Days } from '../actions/promptActions';
import { PROMPT_PAGE_SIZE } from '../consts';
import { RootState } from '../store';

interface PromptsState {
  prompts: Prompt[];
  topPrompts: Prompt[];
  currentPrompt?: Prompt;
  totalCount: number;
  isLoading: boolean;

  publishedOnly?: boolean;
  searchQuery: string;
  page: number;
  pageOffset: number;
  totalPages: number;
  sortOption: PromptSortOptions;
  sortDirection: SortDirection;
  tagOptions: TagCounts;
  selectedTags: string[];
  featuredOnly: boolean;
  recentOnly: boolean;
  pinned?: boolean;
  liked?: boolean;

  promptView: PromptView;
}

const initialState: PromptsState = {
  prompts: [],
  topPrompts: [],
  currentPrompt: undefined,
  totalCount: 0,
  isLoading: false,

  publishedOnly: true,
  searchQuery: '',
  page: 1,
  pageOffset: 0,
  totalPages: 0,
  sortOption: PromptSortOptions.DEFAULT,
  sortDirection: SortDirection.DESC,
  tagOptions: Object.values(Tag).reduce((acc, tag) => {
    acc[tag] = 0;
    return acc;
  }, {} as TagCounts),
  selectedTags: [],
  featuredOnly: false,
  recentOnly: false,

  promptView: PromptView.ALL_PROMPTS,
};

const promptsSlice = createSlice({
  name: 'prompts',
  initialState,
  reducers: {
    setCurrentPrompt: (state, action: PayloadAction<Prompt | undefined>) => {
      state.currentPrompt = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
      state.pageOffset = (action.payload - 1) * PROMPT_PAGE_SIZE;
    },
    setPromptView: (state, action: PayloadAction<PromptView>) => {
      state.promptView = action.payload;
      state.page = 1;
      state.pageOffset = 0;
    },
    setPublishedOnly: (state, action: PayloadAction<boolean | undefined>) => {
      state.publishedOnly = action.payload;
      state.selectedTags = [];
      state.page = 1;
      state.pageOffset = 0;
    },
    setSortOption: (state, action: PayloadAction<PromptSortOptions>) => {
      state.sortOption = action.payload;
      state.page = 1;
      state.pageOffset = 0;
    },
    setSortDirection: (state, action: PayloadAction<SortDirection>) => {
      state.sortDirection = action.payload;
      state.page = 1;
      state.pageOffset = 0;
    },
    setTagOptions: (state, action: PayloadAction<TagCounts>) => {
      state.tagOptions = action.payload;
    },
    setSelectedTags: (state, action: PayloadAction<string[]>) => {
      state.selectedTags = action.payload;
      state.page = 1;
      state.pageOffset = 0;
    },
    setFeaturedOnly: (state, action: PayloadAction<boolean>) => {
      state.featuredOnly = action.payload;
    },
    setRecent: (state, action: PayloadAction<boolean>) => {
      state.recentOnly = action.payload;
    },
    setPinned: (state, action: PayloadAction<boolean | undefined>) => {
      state.pinned = action.payload;
    },
    setLiked: (state, action: PayloadAction<boolean | undefined>) => {
      state.liked = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findPrompts.fulfilled, (state, action: PayloadAction<PromptAPIResponse>) => {
        const { data, totalCount } = action.payload;

        state.prompts = data;
        state.totalCount = totalCount;
        state.totalPages = Math.ceil(totalCount / PROMPT_PAGE_SIZE);
        state.isLoading = false;
      })
      .addCase(findTopPromptsLast30Days.fulfilled, (state, action: PayloadAction<Prompt[]>) => {
        state.topPrompts = action.payload;
      })
      .addMatcher(
        (action: PayloadAction) =>
          action.type.startsWith('prompts/') && action.type.endsWith('/pending'),
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action: PayloadAction) =>
          action.type.startsWith('prompts/') && action.type.endsWith('/rejected'),
        (state) => {
          state.isLoading = false;
        }
      );
  },
});

export const {
  setCurrentPrompt,
  setIsLoading,
  setPage,
  setPromptView,
  setSortDirection,
  setSortOption,
  setSearchQuery,
  setSelectedTags,
  setPublishedOnly,
  setRecent,
  setPinned,
  setTagOptions,
} = promptsSlice.actions;

export const selectPrompts = (state: RootState) => state.prompts;

export default promptsSlice.reducer;
