import { Send } from '@mui/icons-material';
import { Button, styled, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectConversations } from '../../../redux/reducers/conversationReducer';
import { ChatRow, SendButton } from '../../../theme/CustomComponents';
import { ProjectScopeAreaType, ProjectType } from '../../../Types/dealGPT';
import { Llm, MessageFrom } from '../../../Types/enums';
import { Prompt } from '../../../Types/prompt';
import ChatBubbleComponent from '../../Chat/ChatBubble';
import OutputWarning from '../../Chat/OutputWarning';
import ScopeAreasFilterView from '../ScopeAreasFilterView';

const StyledChatContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  maxWidth: '1200px',
});

const ChatContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
});

export type DealGPTChatViewProps = {
  project: ProjectType;
  llm: Llm;
  showChatInfo: boolean;
  errorMessage: string;
  inputRef: React.MutableRefObject<HTMLInputElement | undefined>;
  selectedScopes: ProjectScopeAreaType[];
  handleSubmit: (prompt?: Prompt, input?: string) => void;
  setSelectedScopes: (scopes: ProjectScopeAreaType[]) => void;
};

const DealGPTChatView = ({
  project,
  llm,
  showChatInfo,
  errorMessage,
  inputRef,
  selectedScopes,
  handleSubmit,
  setSelectedScopes,
}: DealGPTChatViewProps): JSX.Element => {
  const { currentMessages, isLoading } = useSelector(selectConversations);
  const currentMessagesRef = useRef(currentMessages);
  const navigate = useNavigate();

  useEffect(() => {
    currentMessagesRef.current = currentMessages;
  }, [currentMessages]);

  const handleStartConversationClick = () => {
    navigate(`/intellio-advantage/${project.id}/chat`);
  };

  return (
    <StyledChatContainer>
      <div style={{ display: 'flex' }}>
        {/* <DocumentsChatButtonsView selectedView={DealGPTView.CHAT} projectId={project.id} /> */}
        {showChatInfo && (
          <div>
            <Typography
              variant="h4"
              sx={{ marginBottom: '16px', fontWeight: '700', fontSize: '36px' }}
            >
              Chat with {project.name}
            </Typography>
          </div>
        )}
        {!showChatInfo && (
          <Button
            sx={{ width: 'fit-content', marginBottom: '10px', height: '35px' }}
            variant="contained"
            onClick={handleStartConversationClick}
          >
            New Chat
          </Button>
        )}
      </div>
      <ChatContainer>
        {currentMessagesRef.current.length === 0 && (
          <ChatBubbleComponent
            llm={llm}
            key={'placeholder'}
            message={{
              from: MessageFrom.AI,
              message:
                'Start a new conversation with me by typing in the box or select an old conversation from the left! 😊',
            }}
            handleSubmit={handleSubmit}
          />
        )}
        {currentMessagesRef.current.map((message, index) => (
          <ChatBubbleComponent
            llm={llm}
            key={index}
            message={message}
            handleSubmit={handleSubmit}
          />
        ))}
        <ScopeAreasFilterView
          project={project}
          selectedScopes={selectedScopes}
          setSelectedScopes={setSelectedScopes}
        />
        <ChatRow>
          <div style={{ paddingBottom: '20px', flexGrow: 1 }}>
            <TextField
              inputRef={(ref) => (inputRef.current = ref)}
              variant="outlined"
              label="Please enter a message"
              InputProps={{
                inputProps: {
                  style: { fontSize: '14px' },
                },
              }}
              multiline
              fullWidth
              InputLabelProps={{ shrink: true }}
              error={!!errorMessage}
              helperText={errorMessage}
              autoFocus
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
              autoComplete="off"
            />
            <OutputWarning />
          </div>
          <div
            style={{
              // Roughly centered on the text field
              marginTop: '7px',
            }}
          >
            <Tooltip title={<Typography variant="caption"> Send </Typography>}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <SendButton
                  onClick={() => handleSubmit()}
                  sx={{ color: '#070154' }}
                  disabled={isLoading}
                >
                  <Send />
                </SendButton>
              </div>
            </Tooltip>
          </div>
        </ChatRow>
      </ChatContainer>
    </StyledChatContainer>
  );
};

export default DealGPTChatView;
