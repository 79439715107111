import { OpenInNew } from '@mui/icons-material';
import { List, ListItem, styled, Typography } from '@mui/material';
import React from 'react';

const StyledLink = styled('a')({
  color: 'inherit',
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'underline',
  },
});

const StyledListItem = styled(ListItem)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  paddingTop: '0',
  paddingBottom: '10px',
});

const OpenInNewIcon = (
  <OpenInNew sx={{ fontSize: '20px', marginRight: '2px', verticalAlign: 'text-top' }} />
);

const UserSpecificStats: React.FC = () => {
  return (
    <>
      <Typography
        variant="subtitle2"
        sx={{
          height: '20px',
          color: '#0047FF',
          margin: 0,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        NIGEL USAGE
      </Typography>
      <div style={{ height: 'inherit' }}>
        <List sx={{ height: 'inherit', marginTop: '10px', paddingTop: '0', paddingBottom: '0' }}>
          <StyledListItem>
            <Typography variant="body2">
              <b>📊 Personal Stats</b>
            </Typography>
            <Typography variant="body2">
              Curious about your personal Nigel usage and stats? Click{' '}
              <StyledLink
                href={
                  'https://app.powerbi.com/groups/363a1305-c15e-49fa-b825-497bf80247db/reports/7c33c3d1-728b-411a-9c5f-d1854718b190/8e206e0c79c581ad8bea?experience=power-bi&bookmarkGuid=6b0f91b18ad5402182e7'
                }
                target="_blank"
              >
                {OpenInNewIcon}
                <b>HERE</b>
              </StyledLink>{' '}
              to see how your usage compares to your department and the entire firm.
            </Typography>
          </StyledListItem>
          <StyledListItem>
            <Typography variant="body2">
              <b>💬 Nigel User Quotes</b>
            </Typography>
            <Typography variant="body2">
              &quot;Extremely useful for brainstorming, creating first drafts, and summarizing key
              information (e.g., SOW, client documents), complex topics, and meeting notes.&quot;
            </Typography>
          </StyledListItem>
        </List>
      </div>
    </>
  );
};

export default UserSpecificStats;
